(function ($, ns) {

    var mapPanel = function () {
        this.$markup = null;
        this.create();
        this.setupListeners();
    };

    mapPanel.prototype = {
        create: function () {

            var markup   = '<div class="map-panel-wrap">' +
                '<div class="map-panel-content"></div>' +
                '<button class="close">&times</button>'+
                '</div>';
            this.$markup = $(markup);
        },
        setupListeners: function () {
            var self = this;
            self.$markup.find('.close').on('click', function () {
                self.hide();
            });
        },
        setContent: function (panelContent, cb) {
            this.$markup.find('.map-panel-content').html(panelContent);
            cb && cb();
        },
        preLoad : function(){
            this.$markup.removeClass('active');
            this.$markup.addClass('loading');
            this.$markup.find('.map-panel-content').html('<span class="loader"></span>');
        },
        show: function () {
            this.$markup.removeClass('loading').addClass('active');
        },
        hide: function () {
            this.$markup.removeClass('loading').removeClass('active');
        }
    };

    ns.mapPanel = mapPanel;

})(jQuery, window.wonderwp);
