(function ($, ns) {
    "use strict";

    var ThemeMap = function (context, opts, init) {

      this.$context = (context instanceof jQuery) ? context : $(context);

      this.map = null;
      this.panel = null;
      this.panelCache = {};
      this.searchCounter = {};

      if (opts) {
        this.opts = $.extend(this.getDefaultOpts(), opts);
      }

      if (init !== false && !$('body').hasClass('wp-admin')) {
        this.init();
      }
    };

    var parentPrototype = window.pew.getRegistryEntry('wdf-plugin-map').classDef.prototype;
    ThemeMap.prototype = jQuery.extend({}, parentPrototype);

    ThemeMap.prototype.loadPanelContent = function (marker) {
      var mk = '';


      // We might use the link as a video link, so if we have youtube in the link, we use it as a video
      if (marker.data.link && (marker.data.link.includes('youtube') || marker.data.link.includes('youtu.be'))) {
        mk += '<iframe class="marker-video" width="450" height="253" src="' + marker.data.link + '?autoplay=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
      }
      // We might use the visual as a before/after component, so we check if we have a before/after component in the visual
      else if (marker.data.visual && marker.data.visual.includes('before-after-component')) {
        mk += marker.data.visual;
      } else if (marker.data.visual && marker.data.visual.length) {
        mk += '<img class="marker-visual" src="' + marker.data.visual + '" alt="" />';
      }

      if (marker.data.col_title) {
        mk += '<div class="marker-collection marker-collection' + marker.data.col + '">' +
          '<div class="custom-marker custom-marker-col' + marker.data.col + '"></div>' +
          '<span>' + marker.data.col_title + '</span>' +
          '</div>';
      }

      mk += '<span class="marker-title">' + marker.data.title.replace('\\', '') + '</span>';

      if (marker.data.content && marker.data.content.length && !marker.data.content.includes('before-after-component')) {
        mk += '<div class="marker-content">' + marker.data.content.replace('\\', '') + '</div>';
      }

      /*if (marker.data.link) {
        mk += '<a class="modaal " data-modaal-type="video" data-modaal-close-text="Fermer" data-modaal-close-aria-label="Fermer (Cliquer sur escape pour fermer)" href="' + marker.data.link + '" ></a>';
      }*/

      return mk;
    };

    window.pew.addRegistryEntry({key: 'wdf-plugin-map', domSelector: '.map-form, .module-map', classDef: ThemeMap});

  }
)(jQuery, window.wonderwp);
