(function ($, ns) {

  "use strict";

  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param context
   * @param opts
   * @param init
   */
  var WwpMap = function (context, opts, init) {
    this.$context = (context instanceof jQuery) ? context : $(context);

    this.map = null;
    this.panel = null;
    this.panelCache = {};

    if (opts) {
      this.opts = $.extend(this.getDefaultOpts(), opts);
    }

    if (init !== false && !$('body').hasClass('wp-admin')) {
      this.init();
    }
  };

  WwpMap.prototype = {
    getDefaultOpts: function () {
      return {
        center: "46.003336431416216, -395.84552653125",
        zoom: 3,
        engine: "Googlemap",
        mapTypeProperties: {
          Googlemap: {
            wantMapScript: "wantGooglemap",
            mapClassName: 'AGmap',
            mapScriptUrl: 'https://maps.googleapis.com/maps/api/js?key=' + ns.keyMap
          },
          OSM: {
            wantMapScript: "wantOSM",
            mapClassName: "AOpenStreetMap",
            mapScriptUrl: "/app/plugins/wwp-map/public/js/OSM/libs/leaflet.min.js"
          }
        }
      };
    },
    init: function () {
      this.$context.addClass('loading');
      if (!this.opts) {
        var loadedOptsString = this.$context.find('.map-opts').html() || '{}',
          loadedOpts = $.parseJSON(loadedOptsString);

        this.opts = $.extend(this.getDefaultOpts(), loadedOpts);
      }
      this.initMap(this.opts, this.getMapCb.bind(this));
    },
    getMapTypeProperty: function (engine, propertyName) {
      if (this.opts.mapTypeProperties && this.opts.mapTypeProperties[engine] && this.opts.mapTypeProperties[engine][propertyName]) {
        return this.opts.mapTypeProperties[engine][propertyName];
      } else {
        var errorMsg = '[mapModule] Unable to get mapTypeProperty "' + propertyName + '" of map type "' + engine + '".';
        if (!this.opts.mapTypeProperties) {
          errorMsg += "\n - mapTypeProperties not found in opts. Maybe there's a mapModule override in the child theme that does not extend default options properly? Cf opts for debug";
        } else if (!this.opts.mapTypeProperties[engine]) {
          errorMsg += "\n - Provided engine not found in mapTypeProperties. Cf opts for debug";
        } else if (!this.opts.mapTypeProperties[engine][propertyName]) {
          errorMsg += "\n - Given property " + propertyName + " not found in mapTypeProperties for engine " + engine + ". Cf opts for debug";
        }
        console.error(errorMsg, this.opts);
        return null;
      }
    },

    //==[ Map ] ===================================================================================

    initMap: function (options, cb) {
      var self = this,
        engine = options.engine || self.opts.engine,
        wantMapScript = self.getMapTypeProperty(engine, 'wantMapScript'),
        mapClassName = self.getMapTypeProperty(engine, 'mapClassName'),
        mapScriptUrl = self.getMapTypeProperty(engine, 'mapScriptUrl');

      ns[wantMapScript](function () {
          try {
            self.map = new ns[mapClassName](self.$context.find('.map-wrap')[0], options, cb);
          } catch (error) {
            console.error('mapModule error while creating a map with the following options : ', options, '. Error received : ', error);
            self.$context.find('.map-wrap').addClass('error');
          }
        },
        mapScriptUrl);
    },
    getMapCb: function () {
      var elmt = this.$context.find('.map-contents');
      if (elmt.length) {
        var content = $.parseJSON(elmt.html());
        this.loadContent(content);
        this.registerEvents();
      } else {
        console.log('[wwp-map] No content found for map');
      }
    },
    registerEvents: function () {
      this.registerGeolocCapabilities();
    },
    loadContent: function (contentOpts) {

      //console.time("map loadContent");
      var perPage = this.opts && this.opts['perPage'] ? this.opts['perPage'] : 20;

      if (contentOpts.collections && contentOpts.collections.length > 0) {
        this.collections = {};

        //Init list view
        if (this.opts.hasListMode) {
          this.createListView();
        }

        //Init legend
        if (contentOpts.legend) {
          this.initLegend(contentOpts.filterable);
        }

        for (var i in contentOpts.collections) {

          //Add collection to map
          var col = this.addCollection(contentOpts.collections[i]);
          this.collections[col.id] = col;

          //Add collection to list view
          if (this.opts.hasListMode) {
            this.$listView.find('.map-collections-container').append(col.$itemsList);
            this.loadColListPage(col.id, 1, perPage);
          }

          //Add collection to legend
          if (contentOpts.legend) {
            this.appendCollectionToLegend(col, contentOpts.filterable);
            if (col.isDeselectedInLegend) {
              this.setCollectionVisibility(col, false);
            }
          }

        }
        if (contentOpts.legend && contentOpts.filterable) {
          this.registerFilters();
        }

      }

      if (contentOpts.kmls && contentOpts.kmls.length > 0) {
        for (var j in contentOpts.kmls) {
          this.addKml(j, contentOpts.kmls[j]);
        }
      }

      //fix critical css vs loaded css
      this.triggerResize();
      this.$context.removeClass('loading');

      //console.timeEnd("map loadContent");
    },
    offsetCenter: function (latlng, offsetx, offsety) {

      // latlng is the apparent centre-point
      // offsetx is the distance you want that point to move to the right, in pixels
      // offsety is the distance you want that point to move upwards, in pixels
      // offset can be negative
      // offsetx and offsety are both optional

      this.map.offsetCenter(latlng, offsetx, offsety);

    },
    //==[ List Mode ] ===================================================================================
    createListView: function () {
      var self = this,
        listView = '<div class="map-list-view">' +
          '<div class="map-collections-container"></div>' +
          '</div>';
      this.$listView = $(listView);
      this.$context.append(this.$listView);

      var togglerListLabel = this.getTranslation('toggle.list.mode'),
        togglerMapLabel = this.getTranslation('toggle.map.mode'),
        toggler = '<button class="view-toggler" data-mode="map" data-togglemap-text="' + togglerMapLabel + '" data-togglelist-text="' + togglerListLabel + '">' + togglerListLabel + '</button>',
        $toggler = $(toggler);

      $toggler.on('click', function (e) {
        e.preventDefault();
        self.toggleMode($toggler);
      });

      this.$context.prepend($toggler);
    },
    refreshListView: function () {
      var perPage = this.opts && this.opts['perPage'] ? this.opts['perPage'] : 20;

      for (let i in this.collections) {
        let col = this.collections[i];
        this.loadColListPage(col.id, 1, perPage);
      }
      ;
    },
    toggleMode: function ($toggler) {
      var activeMode = $toggler.attr('data-mode');
      if (activeMode === 'map') {
        $toggler.attr('data-mode', 'list');
        $toggler.attr('aria-label', $toggler.data('togglemap-text'));
        $toggler.html($toggler.data('togglemap-text'));
        this.$context.find('.map-wrap').fadeOut();
        this.$context.find('.map-list-view').fadeIn();
      } else {
        $toggler.attr('data-mode', 'map');
        $toggler.attr('aria-label', $toggler.data('togglelist-text'));
        $toggler.html($toggler.data('togglelist-text'));
        this.$context.find('.map-wrap').fadeIn();
        this.$context.find('.map-list-view').fadeOut();
        this.triggerResize();
      }
    },

    //==[ Legend ] ===================================================================================

    initLegend: function (filterable) {
      var self = this,
        legendMarkup = '<div class="map-legend-wrap ' + (filterable ? 'with-filters' : 'no-filters') + '">' +
          '<form class="map-legend">' +
          '<ul></ul>' +
          '</form>' +
          '</div>';
      this.$legend = $(legendMarkup);
      this.$context.append(this.$legend);
    },

    appendCollectionToLegend: function (col, filterable) {
      var markerRepresentation = '';
      var filter = '';
      switch (col.markerType) {
        case 'image':
          markerRepresentation = '<img src="' + col.marker + '" alt="" />';
          break;
        case'default':
          markerRepresentation = '<img src="' + this.map.getDefaultMarkerUl() + '" alt="" />';
          break;
        case'css':
          markerRepresentation = '<div class="custom-marker custom-marker-col' + col.id + '"></div>';
          break;
      }
      if (markerRepresentation.length) {
        markerRepresentation = '<div class="marker-representation">' + markerRepresentation + '</div>';
      }
      var id = '';
      if (filterable) {
        id = 'cb-collection-' + col.id;
        var checked = col.isDeselectedInLegend ? '' : 'checked';
        filter = '<input type="checkbox" name="collections[]" value="' + col.id + '" ' + checked + ' ';
        if (id.length) {
          filter += ' id="' + id + '" ';
        }
        filter += '/>';
      }
      var forLabel = id.length ? 'for="' + id + '"' : '';
      var colListItem = '<li class="map-legend-collection-' + col.id + '">' + filter + '<label ' + forLabel + '>' + markerRepresentation + '<span>' + col.title + '</span></label></li>';
      this.$legend.find('ul').append(colListItem);
    },
    registerFilters: function () {
      var self = this;
      var $collectionInputs = this.$legend.find('input[name="collections[]"]');
      $collectionInputs.on('change', function (e) {
        var $cb = $(e.currentTarget);
        var colId = $cb.val();
        var toShow = $cb.is(':checked');
        var col = self.collections[colId];
        self.setCollectionVisibility(col, toShow);
      });
    },

    //==[ Layer ] ===================================================================================

    addCollection: function (col) {
      var hasPoints = (col.points && col.points.length),
        hasKml = (col.kmlUrl && col.kmlUrl.length);

      if (col && (hasPoints || hasKml)) {

        col.slug = this.stringToSlug(col.title.replace('\\', ''));

        //Creating map layer
        var layer = this.createColLayer(col);

        if (hasPoints) {

          //Creating list mode
          if (this.opts.hasListMode) {
            this.createListLayer(col);
          }

          //Adding each point to the map
          for (var i = 0, tot = col.points.length; i < tot; i++) {
            var m = col.points[i];
            m['col'] = col.id;
            m['index'] = i;
            m['col_marker'] = col.marker;
            m['col_title'] = col.title;
            m['col_slug'] = col.slug;
            var marker = null;
            if ((m.lat.length && m.lng.length) || (typeof (m.lat) === 'number' && typeof (m.lng) === 'number')) {
              marker = this.addMarkerToLayer(m, layer, col, i);
              if (this.opts.hasListMode) {
                marker.listItem = this.getMarkerListItem(marker, col, i);
              }
            }
          }
          if (col.hasClustering) {
            layer.markerClusterer.addLayers(layer.markers);
          }
        }

        //Adding KML layer
        if (hasKml) {
          col.loadedKml = this.addKml('kml-col-' + col.id, col.kmlUrl);
        }

        //Registering Events
        this.addLayerEvent(layer, ns.mapEvents.CLICK_OBJECT, this.layerClicked.bind(this));
      }

      return col;
    },
    setCollectionVisibility: function (col, toShow) {
      if (col) {
        var layer = this.map.layers[col.slug];
        if (layer) {
          layer.setVisible(toShow);
        } else {
          console.error('layer ' + col.slug + ' not found');
        }
        var kmlLayer = col.loadedKml;
        if (kmlLayer) {
          this.map.setKmlVisible(kmlLayer, toShow);
        }
      } else {
        console.error('collection ' + colId + ' not found');
      }
    },
    createColLayer: function (col) {

      col.colLayerOpts = col.colLayerOpts || {};

      var groupName = col.slug,
        markerOpts = {};

      var sizes = this.getSizes(col);

      if (col.marker && col.marker.length) {
        var width = sizes[0],
          height = sizes[1];
        markerOpts = {
          icon: {
            url: col.marker,
            size: [width, height],
            scaledSize: sizes,
            legendSize: sizes
          }
        };
      }
      if (col.hasClustering) {
        return this.map.addClusterLayer(groupName, markerOpts, groupName, col.colLayerOpts);
      } else {
        return this.map.addLayer(groupName, markerOpts, groupName, col.colLayerOpts);
      }

    },

    addLayerEvent: function (layer, eventName, cb) {
      if (this.opts.engine === 'OSM') {
        layer.eventDispatcher && layer.eventDispatcher.on(eventName, function (data) {
          cb(data._type, data._object);
        });
      } else {
        google.maps.event.addListener(layer, eventName, cb);
      }
    },
    updateClusters: function () {
      var self = this;

      var clusters = self.map.layers;
      var clusters_keys = Object.keys(clusters);

      clusters_keys.forEach(function (cluster_key) {
        var cluster = clusters[cluster_key];
        var markers = cluster.markers;
        var hasMarkerVisible = false;

        cluster.markerClusterer.clearMarkers();

        markers.forEach(function (marker) {
          if (marker.getVisible()) {
            marker.setVisible(true);
            hasMarkerVisible = true;
            cluster.markerClusterer.addMarker(marker);
          }
        });

        cluster.setVisible(hasMarkerVisible);
        cluster.markerClusterer.redraw();
      });
    },
    layerClicked: function (type, clickedObj) {
      var t = this;
      if (type === 'marker') {
        if (clickedObj.data.linkRedirect) {
          //redirect
          window.location.href = clickedObj.data.link;
        } else {
          t.markerClicked(clickedObj);
        }
      }
    },
    createListLayer: function (col) {
      col.colLayerOpts = col.colLayerOpts || {};
      var colName = col.slug,
        markup = '<div class="map-collection-wrap map-collection-' + col.id + '-wrap" data-col="' + col.id + '" data-colname="' + colName + '">' +
          '<div class="map-collection-title" tabindex="0">' +
          '<span class="col-name">' + col.title.replace('\\', '') + '</span>' +
          '<span class="col-count">(' + col.points.length + ')</span>' +
          '<span class="col-page"></span>' +
          '</div>' +
          '<ul class="items-list map-collection-items"></ul>' +
          '<div class="map-collection-pagination"></div>' +
          '</div>';
      col.$itemsList = $(markup);

      return col.$list;
    },
    loadColListPage: function (colId, pageNo, itemsPerPage) {

      pageNo = parseInt(pageNo);

      var col = this.collections[colId],
        colSlug = col.slug,
        colLayer = this.map.layers[colSlug];

      if (!col || !col.points || !col.points.length) {
        return;
      }

      if (colLayer) {
        var markers = colLayer.markers;

        var visibleMarkers = [];

        for (var k in markers) {
          if (markers[k].visible) {
            visibleMarkers.push(markers[k]);
          }
        }
        this.sortListMarkers(visibleMarkers);

        var offset = (pageNo - 1) * itemsPerPage,
          paginatedMarkers = visibleMarkers.slice(offset, offset + itemsPerPage);

        if (paginatedMarkers.length) {

          var
            currentPageLabel = this.getTranslation('page.courante'),
            goToPageLabel = this.getTranslation('aller.a.page'),
            prevPageLabel = this.getTranslation('page.precedente'),
            nextPageLabel = this.getTranslation('page.suivante'),
            pageLabel = this.getTranslation('page.page')
          ;

          var $dest = col.$itemsList.find('.items-list');
          $dest.html('');
          for (var i in paginatedMarkers) {
            try {
              this.appendMarkerToList(paginatedMarkers[i], $dest);
            } catch (error) {
              console.error('Could not append paginated marker ' + i + ' to list', paginatedMarkers[i], $dest, error);
            }
          }

          col.$itemsList.find('.col-count').html('(' + visibleMarkers.length + ')');
          col.$itemsList.find('.col-page').html(pageLabel + ' ' + pageNo);

          var paginationParams = this.paginate(visibleMarkers.length, pageNo, itemsPerPage);
          //console.log(paginationParams);

          var paginationMarkup = '<ul class="pagination">';

          if (pageNo > 1) {
            var prevPageNum = parseInt(pageNo - 1);
            paginationMarkup += '<li>' +
              '<a class="navlink navclose navprev" href="#' + prevPageNum + '" aria-label="' + prevPageLabel + ' (' + prevPageNum + ' / ' + paginationParams.totalPages + ')">' + prevPageLabel + '</a>' +
              '</li>';
          }

          for (var j in paginationParams.pages) {
            var thisPageNo = paginationParams.pages[j],
              liClass = thisPageNo === pageNo ? ' class="select"' : (thisPageNo === (pageNo + 1) ? ' class="next"' : ''),
              linkTitle = (thisPageNo === pageNo ? currentPageLabel : goToPageLabel) + ' (' + thisPageNo + ' / ' + paginationParams.totalPages + ')';
            paginationMarkup += '<li' + liClass + '>' +
              '<a class="navlink ' + (thisPageNo === pageNo ? ' navcurrent' : '') + '" href="#' + thisPageNo + '" aria-label="' + linkTitle + '">' + thisPageNo + '</a>' +
              '</li>';
          }

          if (pageNo < paginationParams.totalPages) {
            var nextPageNum = parseInt(pageNo + 1);
            paginationMarkup += '<li>' +
              '<a class="navlink navclose navnext" href="#' + nextPageNum + '" aria-label="' + nextPageLabel + ' (' + nextPageNum + ' / ' + paginationParams.totalPages + ')">' + nextPageLabel + '</a>' +
              '</li>';
          }

          paginationMarkup += '</ul>';

          var $paginationMarkup = $(paginationMarkup);
          var self = this;
          $paginationMarkup.find('a').on('click', function (e) {
            e.preventDefault();
            var thatPageNo = $(this).attr('href').replace('#', '');
            self.loadColListPage(colId, thatPageNo, itemsPerPage);
          });

          col.$itemsList.find('.map-collection-pagination').html($paginationMarkup);
        }

        col.$itemsList.find('.map-collection-title').focus();

      }
    },
    sortListMarkers: function (markers) {
      var self = this;

      if (this.latestGeolocPosition) {

        for (var i in markers) {
          markers[i].dist = self.getDistanceFromLatLonInKm(self.latestGeolocPosition.lat, self.latestGeolocPosition.lng, markers[i].getPosition().lat, markers[i].getPosition().lng);
        }

        markers.sort(function (x, y) {
          if (x.dist < y.dist) {
            return -1;
          }
          if (x.dist > y.dist) {
            return 1;
          }
          return 0;
        });
      }
    },
    appendMarkerToList: function (marker, $list) {
      var $li = $('<li class="item map-item"></li>');
      $li.append(marker.listItem);
      $list.append($li);
    },
    //==[ Marker ] ===================================================================================

    getSizes: function (col) {
      return [32, 32];
    },
    addMarkerToLayer: function (m, layer, col, i) {
      if (m && m.data && m.data.length > 0) {
        m.data = JSON.parse(m.data);
      }
      m.markerOpening = col.markerOpening;
      m.markerType = col.markerType;

      var markerOpts = {
        id: col.id + '__' + m.id,
        title: this.stringToSlug(m.title),
        data: m,
        i: i,
        className: m.cssClasses
      };

      if (col.markerType === 'css') {
        markerOpts['optimized'] = false;
        markerOpts['custom'] = true;
        if (col.markerOpening && col.markerOpening === 'none') {
          markerOpts['className'] = 'no-opening';
        }
      }

      var isCluster = layer.isCluster || false;

      var marker = layer.addMarker(m.lat, m.lng, markerOpts, isCluster);
      marker.visible = true;

      if (col.hasHovers) {
        this.enableMarkerHover(marker);
      }

      return marker;
    },
    markerClicked: function (marker) {
      if (marker.data && marker.data.markerOpening) {
        if (marker.data.markerOpening.indexOf('panel') >= 0) {
          this.openPanel(marker);
        } else if (marker.data.markerOpening === 'none') {
          return false;
        } else {
          this.openPopin(marker);
        }
      }
      return false;
    },
    enableMarkerHover: function (marker) {
      var self = this;
      marker.hoverPopin = self.getHoverPopin(marker);
      if (marker.hoverPopin) {
        this.map.enableMarkerHover(marker, self.markerHoverIn.bind(this), self.markerHoverOut.bind(this));
      }
    },
    getMarkerListItem: function (marker, col, i) {
      return this.getListItemContent(marker);
    },

    //==[ Popin ] ===================================================================================

    openPopin: function (marker) {
      var t = this;
      var linkStart = '',
        linkEnd = '';

      if (marker.data.link) {
        var linkClass = 'popin-link';
        linkStart = '<a href="' + marker.data.link + '" class="' + linkClass + '">';
        linkEnd = '</a>';
      }

      var popinContent = (typeof marker.data.markup === 'string') ? marker.data.markup : {
        title: linkStart + marker.data.title.replace('\\', '') + linkEnd,
        img: marker.data.visual ? linkStart + '<img src="' + marker.data.visual + '">' + linkEnd : '',
        desc: (marker.data && marker.data.content) ? marker.data.content.replace('\\', '') : ''
      };

      this.map.openPopin(
        null,
        marker.getPosition(),
        marker,
        popinContent,
        function (popin, popinContent) {
          t.afterOpenPopin(popin, popinContent, marker);
        }
      );

    },
    afterOpenPopin: function (popin, content, marker) {
      if (window.pew) {
        window.pew.enhanceRegistry(this.map.getPopinWrapper(popin));
      }
    },
    getHoverPopin: function (marker) {
      var hoverPopinContent = this.getHoverPopinContent(marker);

      if (hoverPopinContent === false) {
        return false;
      }

      let popinOpts = {
        content: hoverPopinContent
      };

      if (marker && marker.options && marker.options.data && marker.options.data.data && marker.options.data.data.popin_offset) {
        popinOpts.offset = marker.options.data.data.popin_offset;
      }

      return this.map.createInfoWindow(popinOpts, marker);
    },
    markerHoverIn: function (marker) {
      if (window.innerWidth >= 800) {
        this.map.openInfoWindow(marker);
      }
    },
    markerHoverOut: function (marker) {
      this.map.closeInfoWindow(marker);
    },
    getHoverPopinContent: function (marker) {
      var markupContent = '<div class="hover-popin-content">';

      markupContent += '<span class="title">' + marker.data.title.replace('\\', '') + '</span>';

      markupContent += '</div>';
      return $(markupContent)[0];
    },
    getListItemContent: function (marker) {

      return '<div class="listItemContent">' + this.loadPanelContent(marker) + '</div>';
    },

    //==[ Panel ] ===================================================================================

    openPanel: function (marker) {
      var self = this;
      var markerPos = marker.getPosition();
      var mapWidth = self.$context.innerWidth();
      var panelPosition = 'panel-left';

      if (marker.data && marker.data.markerOpening) {
        panelPosition = (marker.data.markerOpening === 'panel') ? 'panel-left' : marker.data.markerOpening;
      }

      var offset = (mapWidth / 6);
      if (panelPosition === 'panel-right') {
        offset = (-offset);
      }
      self.offsetCenter(markerPos, offset, 0);

      if (self.panel === null) {
        self.panel = new ns.mapPanel();
        self.$context.append(self.panel.$markup);
      }
      self.panel.preLoad();

      if (!self.panelCache[marker.data.id]) {
        self.panelCache[marker.data.id] = self.loadPanelContent(marker);
      }
      self.panel.$markup.attr('data-panel-position', panelPosition);

      self.showPanelContent(self.panelCache[marker.data.id]);
    },
    loadPanelContent: function (marker) {
      var mk = '';

      if (marker.data.visual && marker.data.visual.length) {
        mk += '<img class="marker-visual" src="' + marker.data.visual + '" alt="" />';
      }

      mk += '<span class="marker-title">' + marker.data.title.replace('\\', '') + '</span>';

      if (marker.data.content && marker.data.content.length) {
        mk += '<div class="marker-content">' + marker.data.content.replace('\\', '') + '</div>';
      }

      return mk;
    },
    showPanelContent: function (panelContent) {
      var self = this;
      self.panel.setContent(panelContent, function () {
        if (window.pew) {
          window.pew.enhanceRegistry(self.panel.$markup[0]);
        }
        self.panel.show();
      });
    },

    //==[ KML ] ===================================================================================
    addKml: function (kmlId, kmlUrl, onClickCallback) {
      return this.map.addKmlToMap(kmlId, kmlUrl, onClickCallback);
    },

    //==[ Geoloc ] ===================================================================================

    registerGeolocCapabilities: function () {
      this.geolocaliser = new ns.GmapLocalizer();
      this.EventManager = window.EventManager || $(document);
      this.registerGeolocRequestsListener();
      this.registerGeocodingRequestsListener();
      this.registerRecenterListener();
    },
    registerGeolocRequestsListener: function () {
      var self = this;

      this.EventManager.on('geoloc-request', function (e, opts) {
        if (self.opts.id === opts.mapId) {
          if (opts.$trigger) {
            opts.$trigger.addClass('geoloc-loading');
          }
          self.$context.find('.alert').remove();
          self.geolocaliser.handleGeolocRequest(function (position, info, error) {
            if (opts.$trigger) {
              opts.$trigger.removeClass('geoloc-loading');
            }
            if (opts.debugPosition) {
              position = opts.debugPosition;
            }
            self.geolocRequestCallBack(position, info, error, opts);
          });
        }
      });
    },
    geolocRequestCallBack: function (position, info, error, opts) {
      //console.log(position, info, error, opts);
      if (position !== null) {
        this.geolocRequestCallBackSuccess(position, opts);
      } else {
        this.geolocRequestCallBackError(info, error, opts);
      }
    },
    geolocRequestCallBackSuccess: function (position, opts) {
      var self = this,
        newCenter = {lat: position.coords.latitude, lng: position.coords.longitude};

      //Add localisation marker
      var addMarker = (typeof opts.addGeolocMarker === 'undefined') ? true : opts.addGeolocMarker;
      if (addMarker) {
        if (!self.geolocMarker) {
          self.geolocMarker = self.map.getGeolocPositionMarker(newCenter, opts);
        }
        self.geolocMarker.setPosition(newCenter);
      }

      //Recenter Map
      self.map.panTo(newCenter);

      setTimeout(function () {
        //Adjust zoom
        var newZoom = (opts.zoomOnSuccess) ? opts.zoomOnSuccess : (self.map.getZoom() + 2);
        self.map.setZoom(newZoom);
      }, 250);

      //Reorder list mode
      this.latestGeolocPosition = newCenter;
      if (this.opts.hasListMode) {
        this.refreshListView();
      }
    },
    geolocRequestCallBackError: function (info, error, opts) {
      var notifOpts = {
          type: 'error',
          msg: info,
          dest: this.$context,
          focus: true
        }
      ;
      //notifOpts['dest'] = (opts.$trigger) ? opts.$trigger.parent() : this.$context;

      this.EventManager.trigger('notification', notifOpts);
    },
    registerGeocodingRequestsListener: function () {
      var self = this;
      this.EventManager.on('geocoding-request', function (e, opts) {
        if (self.opts.id === opts.mapId) {
          if (opts.$trigger) {
            opts.$trigger.addClass('geocoding-loading');
          }
          self.$context.find('.alert').remove();
          self.geolocaliser.geocodeAddress(opts.address, function (res, info) {
            if (opts.$trigger) {
              opts.$trigger.removeClass('geocoding-loading');
            }

            var position = null, error = null;
            if (res && res[0] && res[0].geometry && res[0].geometry.location) {
              position = {
                coords: {
                  latitude: res[0].geometry.location.lat(),
                  longitude: res[0].geometry.location.lng()
                }
              };
            } else {
              error = {};
            }

            self.geolocRequestCallBack(position, info, error, opts);
          });
        }
      });
    },
    registerRecenterListener: function () {
      var self = this;
      this.EventManager.on('map-recenter', function (e, opts) {
        if (self.opts.id === opts.mapId) {
          var newCenter = opts.newCenter,
            position = {
              coords: {
                latitude: opts.newCenter[0],
                longitude: opts.newCenter[1]
              }
            };

          self.geolocRequestCallBack(position, {}, {}, opts);
        }
      });
    },

    //==[ Other ] ===================================================================================

    stringToSlug: function (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

      return str;
    },

    paginate: function (totalItems, currentPage, pageSize, maxPages) {
      currentPage = currentPage || 1;
      pageSize = pageSize || 10;
      maxPages = maxPages || 5;

      // calculate total pages
      var totalPages = Math.ceil(totalItems / pageSize);

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      var startPage, endPage;
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      var startIndex = (currentPage - 1) * pageSize;
      var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      var pages = new Array((endPage + 1) - startPage).join().split(',').map(function (key, i) {
        return startPage + i;
      });

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      };
    },

    getTranslation: function (key) {
      return ns.i18n && ns.i18n.map && ns.i18n.map[key] ? ns.i18n && ns.i18n.map && ns.i18n.map[key] : key;
    },

    triggerResize: function () {
      if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      } else {
        window.dispatchEvent(new Event('resize'));

      }
    },

    getDistanceFromLatLonInKm: function (lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },

    deg2rad: function (deg) {
      return deg * (Math.PI / 180)
    }

  };

  if (ns && ns.app) {
    ns.app.registerModule('map', WwpMap);
  } else {
    if (window.pew) {
      window.pew.addRegistryEntry({key: 'wdf-plugin-map', domSelector: '.map-form, .module-map', classDef: WwpMap});
    }
  }

})(jQuery, window.wonderwp);

