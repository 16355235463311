var namespace = window.wonderwp;

namespace.mapEvents = {
  CLICK_OBJECT: 'click_object',
  MOUSE_OVER_OBJECT: 'mouseover_object',
  KML_LOADED: 'kml_loaded'
};

namespace.mapObjectTypes = {
  MARKER: 'marker',
  POLYLINE: 'polyline',
  POLYGON: 'polygon',
  OBJECT: 'object',
  KML: 'kml'
};
