window.wonderwp.needOSM(function () {
  (function ($, L, namespace) {
    if (!L) return;
    "use strict";

    // ------------------------------------------------------------------------
    // OSMLayer
    // ------------------------------------------------------------------------

    /**
     * create a new map layer
     * @param {NoeOSM} map
     * @param {string} name
     * @param {MarkerOptions} defOptMarker
     * @returns {OSMLayer}
     */
    function OSMLayer(map, name, defOptMarker, defOptPolyline, defOptPolygon, defOptKml) {

      this.map = map;
      this.name = name ? name : 'all';

      this.defOptMarker = defOptMarker || {};

      this.defOptKml = defOptKml || {};

      var defaultPolyline = {
        path: [],
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
        clickable: false
      };

      this.defOptPolyline = defOptPolyline ? $.extend({}, defaultPolyline, defOptPolyline) : defaultPolyline;

      var defaultPolygon = {
        paths: [],
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: '#0000FF',
        fillOpacity: 0.3,
        clickable: false
      };

      this.defOptPolygon = defOptPolygon ? $.extend({}, defaultPolygon, defOptPolygon) : defaultPolygon;

      this.visible = true;
      this.currentId = 0;
      this.markers = [];
      this.polylines = [];
      this.polygons = [];
      this.objects = [];
      this.kmls = [];
      this.eventDispatcher = L.marker([0, 0]);

      this.layer = L.featureGroup().addTo(this.map.map);

      this.map.layers[this.name] = this;

    }

    OSMLayer.Events = namespace.mapEvents;
    OSMLayer.ObjectTypes = namespace.mapObjectTypes;

    /**
     * show / hide layer
     * @param {boolean} visible
     */
    OSMLayer.prototype.setVisible = function (visible) {
      if (visible === this.visible) return;
      var t = this;

      if (visible) {
        t.layer.addTo(t.map.map);
      } else {
        t.map.map.removeLayer(t.layer);
      }

      t.visible = visible;

    };

    /**
     * toggle layer
     */
    OSMLayer.prototype.toggle = function () {
      this.setVisible(!this.visible);
    };

    /**
     *
     * @param {object} obj
     * @param {string} event
     * @param {function} callback
     */
    OSMLayer.prototype.addObjectListener = function (obj, event, callback, context) {
      obj.on(event, callback, context);
    };

    OSMLayer.prototype.addCustomObject = function (obj, addToBounds) {
      this.objects.push(obj);
    };

    /**
     * create a marker
     * @param {float} lat
     * @param {float} lng
     * @param {MarkerOptions} opt
     * @returns {Marker}
     */
    OSMLayer.prototype.addMarker = function (lat, lng, opt, nodraw) {

      opt = $.extend({}, this.defOptMarker, opt);

      if (opt.icon) {
        opt.icon = namespace.wwpIconOptToOSM(opt.icon);
      }/* else {
        opt.icon = L.icon({
          iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
          iconSize: [25, 41],
          shadowUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png',
          shadowSize: [41, 41],
        });
      }*/

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      var marker;
      if (opt.custom) {
        opt.lat = lat;
        opt.lng = lng;
        var customMarkerFactory = new namespace.OsmCustomMarker(opt);
        marker = customMarkerFactory.getMarker();
      } else {
        marker = L.marker([lat, lng], opt);
        let icon = marker.getIcon();
        if (opt && opt.className && opt.className.length > 0 && icon) {
          icon.options.className=opt.className;
        }

      }
      marker.noeLayer = this;

      for (var i in opt) {
        marker[i] = opt[i];
      }
      marker.getPosition = function () {
        return this._latlng;
      };

      this.layer.addLayer(marker);

      this.markers.push(marker);

      var t = this;
      this.addObjectListener(marker, 'click', function (e) {
        t.eventDispatcher.fireEvent(OSMLayer.Events.CLICK_OBJECT, {
          _type: OSMLayer.ObjectTypes.MARKER,
          _object: marker
        });
      });
      this.addObjectListener(marker, 'mouseover', function (e) {
        t.eventDispatcher.fireEvent(OSMLayer.Events.MOUSE_OVER_OBJECT, {
          _type: OSMLayer.ObjectTypes.MARKER,
          _object: marker
        });
      });

      return marker;

    };

    /**
     * cleanly remove a marker from this layer, removing from markers array
     * @param {Marker} marker
     */
    OSMLayer.prototype.removeMarker = function (marker) {
      // remove marker from map
      this.layer.removeLayer(marker);

      // remove marker from array
      var index = this.markers.indexOf(marker);
      if (index > -1) {
        this.markers.splice(index, 1);
      }
    };

    /**
     * add a polyline
     *
     * options list :
     * clickable
     * fillColor
     * fillOpacity
     * strokeColor
     * strokeOpacity
     * strokeWeight
     * visible
     * zIndex
     *
     * @param {array} points
     * @param {object} opt
     */
    OSMLayer.prototype.addPolyline = function (points, opt) {

      opt = $.extend({}, this.defOptPolyline, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      //Creation du polyline
      opt.color = opt.strokeColor;
      opt.opacity = opt.strokeOpacity;
      opt.weight = opt.strokeWeight;
      var poly = new L.Polyline(points, opt);
      poly.noeLayer = this;
      this.polylines.push(poly);
      this.layer.addLayer(poly);

      var t = this;
      this.addObjectListener(poly, 'click', function (e) {
        t.eventDispatcher.fireEvent(OSMLayer.Events.CLICK_OBJECT, {
          _type: OSMLayer.ObjectTypes.POLYLINE,
          _object: poly
        });
      });

      return poly;

    };

    /**
     * add a polygon
     *
     * options list :
     * clickable
     * fillColor
     * fillOpacity
     * strokeColor
     * strokeOpacity
     * strokeWeight
     * visible
     * zIndex
     *
     * @param {array} points
     * @param {object} opt
     */
    OSMLayer.prototype.addPolygon = function (points, opt) {

      opt = $.extend({}, this.defOptPolygon, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      //Creation du polygon
      opt.color = opt.strokeColor;
      opt.opacity = opt.strokeOpacity;
      opt.weight = opt.strokeWeight;
      var poly = new L.Polygon(points, opt);
      poly.noeLayer = this;
      this.polygons.push(poly);
      this.layer.addLayer(poly);

      var t = this;
      this.addObjectListener(poly, 'click', function (e) {
        t.eventDispatcher.fireEvent(OSMLayer.Events.CLICK_OBJECT, {
          _type: OSMLayer.ObjectTypes.POLYGON,
          _object: poly
        });
      });

      return poly;

    };

    /**
     * create a kml
     * @param {string} url
     * @param {object} opt
     * @returns KmlLayer
     */
    OSMLayer.prototype.addKml = function (url, opt) {
      var scope = this;

      opt = opt || {};

      opt = $.extend({}, this.defOptKml, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      var kml = new L.KML(url);
      kml.noeLayer = this;

      this.layer.addLayer(kml);
      this.kmls.push(kml);

      return kml;

    };

    /**
     * remove all objects
     */
    OSMLayer.prototype.clear = function () {

      var i;

      i = this.markers.length;
      while (i--) {
        this.layer.removeLayer(this.markers[i]);
      }
      this.markers = [];

      i = this.polylines.length;
      while (i--) {
        this.layer.removeLayer(this.polylines[i]);
      }
      this.polylines = [];

      i = this.polygons.length;
      while (i--) {
        this.layer.removeLayer(this.polygons[i]);
      }
      this.polygons = [];

      i = this.kmls.length;
      while (i--) {
        this.layer.removeLayer(this.kmls[i]);
      }
      this.listeners = [];

    };

    /**
     * run callback for each objects in this layer
     * @param {fucntion} callback
     */
    OSMLayer.prototype.each = function (callback) {
      if (callback) {
        var i = this.markers.length;
        while (i--) callback(this.markers[i], OSMLayer.ObjectTypes.MARKER);

        i = this.polylines.length;
        while (i--) callback(this.polylines[i], OSMLayer.ObjectTypes.POLYLINE);

        i = this.polygons.length;
        while (i--) callback(this.polygons[i], OSMLayer.ObjectTypes.POLYGON);

        i = this.objects.length;
        while (i--) callback(this.objects[i], OSMLayer.ObjectTypes.OBJECT);

        i = this.kmls.length;
        while (i--) callback(this.kmls[i], OSMLayer.ObjectTypes.KML);
      }

    };

    OSMLayer.prototype.getBounds = function () {
      return this.layer.getBounds();
    };

    namespace.OSMLayer = OSMLayer;

  })(jQuery, window.L, window.wonderwp);
});
