var namespace = window.wonderwp,
  w = window;
namespace.__needOSM = [];
namespace.__wantOSM = [];
namespace.__OSMLoading = false;

namespace.__OSMLoaded = function () {
  if (w.L) {
    for (var i = 0; i < namespace.__needOSM.length; i++) {
      namespace.__needOSM[i]();
    }

    for (i = 0; i < namespace.__wantOSM.length; i++) {
      namespace.__wantOSM[i]();
    }
    namespace.__OSMLoading = false;
  } else {
    namespace.__OSMLoading = false;
    namespace.wantOSM();
  }
};

/**
 * wrap any code that need google map on the callback of this function
 * @param {function} cb
 */
namespace.needOSM = function (cb) {
  if (w.L) {
    cb && cb();
  } else {
    cb && namespace.__needOSM.push(cb);
  }
};

/**
 * use this function when a part of your code want open street map, callback will execute when script is loaded asynchronously
 * @param {function} cb
 * @param {string} url optional
 */
namespace.wantOSM = function (cb, url) {
  if (w.L) {
    cb && cb();
  } else {
    cb && namespace.__wantOSM.push(cb);
    if (!namespace.__OSMLoading) {
      namespace.__OSMLoading = true;
      //load leaflet script
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = function () {
        namespace.__OSMLoaded();
      };
      document.body.appendChild(script);
    }
  }
};

namespace.wwpIconOptToOSM = function(wwpIconOpt) {
  var iconOpt = {};

  if(wwpIconOpt.url) {
    iconOpt.iconUrl = wwpIconOpt.url;
  }
  if(wwpIconOpt.scaledSize) {
    iconOpt.iconSize = wwpIconOpt.scaledSize;
  }
  if(wwpIconOpt.anchor) {
    iconOpt.iconAnchor = wwpIconOpt.anchor;
  } else if(wwpIconOpt.scaledSize) {
    // anchor par default en bas au centre
    iconOpt.anchor = [iconOpt.iconSize[0] / 2, iconOpt.iconSize[1]];
  }
  return L.icon(iconOpt);
};
