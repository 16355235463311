
(window.wonderwp || window).needGooglemap(function() {
    (function($, namespace) {

        function CustomMarker(opt) {
            var className = opt.className || '';
            var content = opt.content || '';
            this.latlng_ = opt.latlng;

            if(opt.data){
                this.data = opt.data;
                if(this.data.col){
                    className+=' custom-marker-col'+this.data.col;
                }
            }

            if(opt.i){
                className+=' marker-index-'+opt.i;
            }

            this.div_ = document.createElement('div');
            this.div_.className = 'custom-marker '+className;
            this.div_.innerHTML = content;

            var scope = this;
            this.div_.addEventListener("click", function(event) {
                google.maps.event.trigger(scope, "click", scope);
            });

            // Once the LatLng and text are set, add the overlay to the map.  This will
            // trigger a call to panes_changed which should in turn call draw.
            this.setMap(opt.map);
        }

        CustomMarker.prototype = new google.maps.OverlayView();

        CustomMarker.prototype.onAdd = function() {
            var pane = this.getPanes().overlayImage;
            pane.appendChild(this.div_);
            this.div_.style.visibility = "visible";
        };

        CustomMarker.prototype.setVisible = function(isVisible){
            this.div_.style.visibility = isVisible ? "visible" : "hidden";
        };

        CustomMarker.prototype.draw = function() {

//            google.maps.event.addDomListener(div, "click", function(event) {
//                google.maps.event.trigger(me, "click");
//            });

            // Position the overlay
            var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
            if (point) {
                this.div_.style.left = point.x + 'px';
                this.div_.style.top = point.y + 'px';
            }
        };

        CustomMarker.prototype.remove = function() {
            // Check if the overlay was on the map and needs to be removed.
            if (this.div_) {
                //this.div_.removeEventListener("click");
                if(this.div_.parentNode) {
                    this.div_.parentNode.removeChild(this.div_);
                }
            }
        };

        CustomMarker.prototype.getPosition = function() {
            return this.latlng_;
        };

        CustomMarker.prototype.getBounds = function() {
            return new google.maps.LatLngBounds(this.latlng_, this.latlng_);
        };

        CustomMarker.prototype.addClass = function(classname) {
            this.div_.classList.add(classname);
        };
        CustomMarker.prototype.removeClass = function(classname) {
            this.div_.classList.remove(classname);
        };

        namespace.GmapCustomMarker = CustomMarker;

    })(jQuery, window.wonderwp || window);
});
