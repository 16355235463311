/**
 * Created by jeremydesvaux on 18/05/2017.
 */

(window.wonderwp || window).needGooglemap(function () {
  (function ($, _gm, namespace) {
    if (!_gm) return;
    "use strict";

    // ------------------------------------------------------------------------
    // GmapLayer
    // ------------------------------------------------------------------------

    /**
     * create a new map layer
     * @returns {GmapLayer}
     */
    function GmapLayer(map, name, defOptMarker, groupName, layerOptions) {

      this.map = map;
      this.name = name ? name : 'all';

      this.defOptMarker = defOptMarker || {};
      this.defOptMarker.map = this.map;

      this.defOptKml = {};
      this.defOptKml.map = this.map;

      var defaultPolyline = {
        path: [],
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
        clickable: false,
        map: this.map
      };

      this.defOptPolyline = defaultPolyline;

      var defaultPolygon = {
        paths: [],
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: '#0000FF',
        fillOpacity: 0.3,
        clickable: false,
        map: this.map
      };

      this.defOptPolygon = defaultPolygon;

      this.visible = true;
      this.currentId = 0;
      this.markers = [];
      this.polylines = [];
      this.polygons = [];
      this.listeners = [];
      this.objects = [];
      this.kmls = [];
      this.bounds = new _gm.LatLngBounds();

      this.map.layers[this.name] = this;

    }

    namespace.gmapExtend(GmapLayer, _gm.MVCObject);

    GmapLayer.Events = namespace.mapEvents;
    GmapLayer.ObjectTypes = namespace.mapObjectTypes;

    /**
     * show / hide layer
     * @param {boolean} visible
     */
    GmapLayer.prototype.setVisible = function (visible) {
      if (visible === this.visible) return;
      var t = this;
      this.each(function (object, type) {
        if (type === GmapLayer.ObjectTypes.KML) {
          object.setMap(visible ? t.map : null);
        } else {
          object.setVisible(visible);
        }
      });

      t.visible = visible;

    };

    /**
     * toggle layer
     */
    GmapLayer.prototype.toggle = function () {
      this.setVisible(!this.visible);
    };

    /**
     *
     * @param {object} obj
     * @param {string} event
     * @param {function} callback
     */
    GmapLayer.prototype.addObjectListener = function (obj, event, callback) {

      var e = _gm.event.addListener(obj, event, callback);
      this.listeners.push(e);

    };

    GmapLayer.prototype.addCustomObject = function (obj, addToBounds) {
      this.objects.push(obj);
      addToBounds === true && this.addToBounds(obj);
    };

    /**
     * create a marker
     * @param {float} lat
     * @param {float} lng
     * @param {MarkerOptions} opt
     * @returns {Marker}
     */
    GmapLayer.prototype.addMarker = function (lat, lng, opt, nodraw) {

      opt = $.extend({}, this.defOptMarker, opt);
      opt.position = new _gm.LatLng(lat, lng);

      if (opt.icon) {
        if (opt.icon.size) {
          opt.icon.size = $.isArray(opt.icon.size) ? new _gm.Size(opt.icon.size[0], opt.icon.size[1]) : opt.icon.size;
        }
        if (opt.icon.scaledSize) {
          opt.icon.scaledSize = $.isArray(opt.icon.scaledSize) ? new _gm.Size(opt.icon.scaledSize[0], opt.icon.scaledSize[1]) : opt.icon.scaledSize;
        }
      }

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      var marker;
      if (opt.custom) {
        opt.latlng = new _gm.LatLng(lat, lng);
        marker = new namespace.GmapCustomMarker(opt);
        marker.id = opt.id;
      } else {
        marker = new _gm.Marker(opt);
      }
      marker.noeLayer = this;

      this.markers.push(marker);

      this.addToBounds(marker);

      var t = this;
      this.addObjectListener(marker, 'click', function (e) {
        _gm.event.trigger(t, GmapLayer.Events.CLICK_OBJECT, GmapLayer.ObjectTypes.MARKER, marker);
      });
      this.addObjectListener(marker, 'mouseover', function (e) {
        _gm.event.trigger(t, GmapLayer.Events.MOUSE_OVER_OBJECT, GmapLayer.ObjectTypes.MARKER, marker);
      });

      if (!this.visible) marker.setVisible(false);

      return marker;

    };

    /**
     * cleanly remove a marker from this layer, removing from markers array
     * @param {Marker} marker
     */
    GmapLayer.prototype.removeMarker = function (marker) {
      // remove marker from map
      marker.setMap(null);

      // remove marker from array
      var index = this.markers.indexOf(marker);
      if (index > -1) {
        this.markers.splice(index, 1);
      }
    };

    /**
     * return a marker from its id
     * @param {int} id
     * @returns {Marker}
     */
    GmapLayer.prototype.getMarkerById = function (id) {
      for (var i in this.markers) {
        if (this.markers[i].id == id) {
          return this.markers[i];
        }
      }
    };

    GmapLayer.prototype.highlightMarker = function (marker) {
      var scope = this;

      //Center map on the marker
      scope.map.panTo(marker.getPosition());

      if (marker.map === null) {
        //The marker is not attached to the map, probably behind a cluster
        if (marker.noeLayer.isCluster) {

          //Finding current cluster
          var currentCluster = null;
          for (var i in marker.noeLayer.markerClusterer.clusters_) {
            for (var j in marker.noeLayer.markerClusterer.clusters_[i]['markers_']) {
              if (marker.id == marker.noeLayer.markerClusterer.clusters_[i]['markers_'][j]['id']) {
                currentCluster = marker.noeLayer.markerClusterer.clusters_[i];
                var currentClusterIcon = currentCluster['clusterIcon_']['div_'],
                  timing = 500,
                  movement = '10px';
                $(currentClusterIcon)
                  .animate({top: "-=" + movement}, timing)
                  .animate({top: "+=" + movement}, timing)
                  .animate({top: "-=" + movement}, timing)
                  .animate({top: "+=" + movement}, timing)
                  .animate({top: "-=" + movement}, timing)
                  .animate({top: "+=" + movement}, timing);
              }
            }
          }
        }
      } else {
        //The marker is visible => bounce it

        //Bounce marker
        marker.setAnimation && marker.setAnimation(google.maps.Animation.BOUNCE);
        //On arrete de bouncer quand on bouge la carte
        setTimeout(function () {
          google.maps.event.addListener(scope.map, 'center_changed', function () {
            marker.setAnimation && marker.setAnimation(null);
          });
        }, 500);
        //Ou apres 5 secondes
        setTimeout(function () {
          marker.setAnimation && marker.setAnimation(null);
        }, 4900);

      }

    };

    /**
     * add a polyline
     *
     * options list :
     * clickable
     * draggable
     * editable
     * icons
     * strokeColor
     * strokeOpacity
     * strokeWeight
     * visible
     * zIndex
     *
     * @param {array} points
     * @param {object} opt
     */
    GmapLayer.prototype.addPolyline = function (points, opt) {

      opt = $.extend({}, this.defOptPolyline, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      if (points) {
        var pointsLatLng = [];
        for (var i in points) {
          var latLng = new _gm.LatLng(points[i][0], points[i][1]);
          pointsLatLng.push(latLng);
        }
        opt.path = pointsLatLng;
      }

      //Creation du polyline
      var poly = new _gm.Polyline(opt);
      poly.noeLayer = this;
      this.polylines.push(poly);
      this.addToBounds(poly);

      var t = this;
      this.addObjectListener(poly, 'click', function (e) {
        _gm.event.trigger(t, GmapLayer.Events.CLICK_OBJECT, GmapLayer.ObjectTypes.POLYLINE, poly, e);
      });

      if (!this.visible) poly.setVisible(false);

      return poly;

    };

    /**
     * add a polygon
     *
     * options list :
     * clickable
     * draggable
     * editable
     * fillColor
     * fillOpacity
     * strokeColor
     * strokeOpacity
     * strokePosition
     * strokeWeight
     * visible
     * zIndex
     *
     * @param {array} points
     * @param {object} opt
     */
    GmapLayer.prototype.addPolygon = function (points, opt) {

      opt = $.extend({}, this.defOptPolygon, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      var pointsLatLng = [];
      for (var i in points) {
        var latLng = new _gm.LatLng(points[i][0], points[i][1]);
        pointsLatLng.push(latLng);
      }

      //Creation du polygon
      opt.path = pointsLatLng;
      var poly = new _gm.Polygon(opt);
      poly.noeLayer = this;
      this.polygons.push(poly);
      this.addToBounds(poly);

      var t = this;
      this.addObjectListener(poly, 'click', function (e) {
        _gm.event.trigger(t, GmapLayer.Events.CLICK_OBJECT, GmapLayer.ObjectTypes.POLYGON, poly, e);
      });

      if (!this.visible) poly.setVisible(false);

      return poly;

    };

    /**
     * create a kml
     * @param {string} url
     * @param {object} opt
     * @returns KmlLayer
     */
    GmapLayer.prototype.addKml = function (url, opt) {
      var scope = this;

      opt = opt || {};

      opt = $.extend({}, this.defOptKml, opt);

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      opt.url = url;
      var kml = new _gm.KmlLayer(opt);
      kml.noeLayer = this;

      // listen viewport change to update map bounds
      var t = this;
      _gm.event.addListener(kml, 'defaultviewport_changed', function () {
        scope.majBounds();
        _gm.event.trigger(t, GmapLayer.Events.KML_LOADED, kml);
      });

      this.kmls.push(kml);

      //this.addToBounds(kml);

      if (!this.visible) kml.setMap(null);

      return kml;

    };

    /**
     * remove all objects
     */
    GmapLayer.prototype.clear = function () {

      var i;

      i = this.markers.length;
      while (i--) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      i = this.polylines.length;
      while (i--) {
        this.polylines[i].setMap(null);
      }
      this.polylines = [];

      i = this.polygons.length;
      while (i--) {
        this.polygons[i].setMap(null);
      }
      this.polygons = [];

      i = this.kmls.length;
      while (i--) {
        this.kmls[i].setMap(null);
      }
      // remove listeners
      i = this.listeners.length;
      while (i--) {
        _gm.event.removeListener(this.listeners[i]);
      }
      this.listeners = [];

      this.bounds = new _gm.LatLngBounds();

    };

    /**
     * add object to layer bounds
     * @param {object} obj
     */
    GmapLayer.prototype.addToBounds = function (obj) {
      if (obj.getBounds) {
        this.bounds.union(obj.getBounds());
      }
    };

    /**
     * get bounds of this layer
     * @returns {LatLngBounds}
     */
    GmapLayer.prototype.majBounds = function () {

      var bounds = new _gm.LatLngBounds();

      this.each(function (obj, type) {
        var objBounds = null;

        if (type == GmapLayer.ObjectTypes.KML) {
          objBounds = obj.getDefaultViewport();
        } else if (obj.visible) {
          objBounds = obj.getBounds();
        }

        objBounds && bounds.union(objBounds);
      });
      this.bounds = bounds;

    };

    /**
     * run callback for each objects in this layer
     * @param {function} callback
     */
    GmapLayer.prototype.each = function (callback) {
      if (callback) {
        if (this.markers) {
          var i = this.markers.length;
          while (i--) callback(this.markers[i], GmapLayer.ObjectTypes.MARKER);
        }

        if (this.polylines) {
          i = this.polylines.length;
          while (i--) callback(this.polylines[i], GmapLayer.ObjectTypes.POLYLINE);
        }

        if (this.polygons) {
          i = this.polygons.length;
          while (i--) callback(this.polygons[i], GmapLayer.ObjectTypes.POLYGON);
        }

        if (this.objects) {
          i = this.objects.length;
          while (i--) callback(this.objects[i], GmapLayer.ObjectTypes.OBJECT);
        }

        if (this.kmls) {
          i = this.kmls.length;
          while (i--) callback(this.kmls[i], GmapLayer.ObjectTypes.KML);
        }
      }

    };

    namespace.GmapLayer = GmapLayer;

  })(jQuery, window.google && google.maps, window.wonderwp || window);
});
