var namespace = window.wonderwp;
namespace.needGooglemap(function () {

  (function ($, _gm, namespace) {

    if (!_gm) return;
    "use strict";

    // ------------------------------------------------------------------------
    // AGmap
    // ------------------------------------------------------------------------

    /**
     * create a new map
     * @param {DOMElement} el
     * @param {object} opt
     * @param {fn} callback
     * @returns AGmap
     */
    function AGmap(el, opt, callback) {
      var t = this;
      t.el = el;
      if (!opt.zoom) throw "Zoom required";
      if (!opt.center) throw "Center required";
      if (typeof opt.center == 'string') {
        opt.center = opt.center.split(',');
      }
      if (!opt.mapTypeControlOptions || !opt.mapTypeControlOptions.mapTypeIds) {
        if (!opt.mapTypeControlOptions) {
          opt.mapTypeControlOptions = {};
        }
        opt.mapTypeControlOptions.mapTypeIds = [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.TERRAIN,
          google.maps.MapTypeId.SATELLITE,
        ];
      }
      ;
      this.__mapTypeIds = opt.mapTypeControlOptions.mapTypeIds;
      opt.center = $.isArray(opt.center) ? new _gm.LatLng(opt.center[0], opt.center[1]) : opt.center;
      _gm.Map.call(t, el, opt);
      t.layers = [];
      t.popins = [];
      t.trafficLayer = null;
      t.clusterGroups = {};

      t.loaded = false;
      _gm.event.addListener(this, 'tilesloaded', function () {
        if (callback && !t.loaded) {
          callback();
        }
        t.loaded = true;
      });

      if (opt.showScrollBtn) {
        this.initScrollBtn();
      }

      if (opt.disableScroll) {
        this.setScrollable(false);
      }

      if (opt.customZoomButtons) {
        this.initZoomButtons();
      }

      $(el).data('gmap', this);

      if (!t.loaded && $(el).is(':hidden')) {
        var timer = setInterval(function () {
          if (!$(el).is(':hidden')) {
            _gm.Map.call(t, el, opt);
            $(el).data('gmap').resize();
            clearInterval(timer);
          }
        }, 300);
      }
    };
    namespace.gmapExtend(AGmap, _gm.Map);
    namespace.AGmap = AGmap;

    /**
     * create a layer on the map
     * @returns {GmapLayer}
     */
    AGmap.prototype.addLayer = function (name, defOptMarker, groupName, layerOptions) {

      var layer = new namespace.GmapLayer(this, name, defOptMarker, groupName, layerOptions);
      this.layers[layer.name] = layer;
      return layer;

    };

    /**
     * create a clustered layer on the map
     * @param {string} name
     * @param {object} defOptMarker
     * @param {string} groupName to group multiple layers in one same cluster group
     * @param {object} layerOptions
     * @returns {GmapCluster}
     */
    AGmap.prototype.addClusterLayer = function (name, defOptMarker, groupName, layerOptions) {

      var layer = new namespace.GmapCluster(this, name, defOptMarker, groupName, layerOptions);
      this.layers[layer.name] = layer;
      return layer;

    };

    /**
     * get a layer by its name
     * @param {string} name
     * @returns {GmapLayer|GmapCluster}
     */
    AGmap.prototype.getLayer = function (name) {
      return this.layers[name] === undefined ? false : this.layers[name];
    };

    /**
     * remove layer by its name
     * @param {string} name
     */
    AGmap.prototype.removeLayer = function (name) {
      var layer = this.getLayer(name);
      if (layer === false) return;

      layer.clear();
      delete this.layers[name];
    };

    AGmap.prototype.createInfoWindow = function (opt, marker) {
      return new _gm.InfoWindow({
        content: opt.content
      });
    }

    /**
     * open a default infowindow on the map
     * @param {object} opt
     */
    AGmap.prototype.openInfoWindow = function (marker) {
      var infoWindow = marker.hoverPopin;
      if (this.lastWindow && this.lastWindow.close) this.lastWindow.close(); //ferme la derniere bulle ouverte

      this.lastWindow = infoWindow;

      infoWindow.open({
        anchor: marker,
        map: this,
        shouldFocus: false
      });

    };

    AGmap.prototype.closeInfoWindow = function (marker) {
      marker.hoverPopin.close();
    }

    AGmap.prototype.enableMarkerHover = function (marker, cbIn, cbOut) {
      if (marker.data.markerType === 'css') {
        var $div = $(marker.div_);
        $div.data('marker', marker);
        $div.hover(
          function () {
            var marker = $(this).data('marker');
            cbIn(marker);
          }, function () {
            var marker = $(this).data('marker');
            cbOut(marker);
          }
        );
      } else {
        marker.addListener('mouseover', function () {
          cbIn(this)
        });
        marker.addListener('mouseout', function () {
          cbOut(this)
        });
      }
    }

    AGmap.prototype.limitMapBound = function (n, e, s, w) {
      var strictBounds = new _gm.LatLngBounds(new _gm.LatLng(s, w), new _gm.LatLng(n, e)),
        scope = this;
      this.limitBoundListener && _gm.event.removeListener(this.limitBoundListener);

      var checkLimit = function () {
        if (strictBounds.contains(scope.getCenter())) return;

        // We're out of bounds - Move the map back within the bounds

        var c = scope.getCenter(),
          x = c.lng(),
          y = c.lat(),
          maxX = strictBounds.getNorthEast().lng(),
          maxY = strictBounds.getNorthEast().lat(),
          minX = strictBounds.getSouthWest().lng(),
          minY = strictBounds.getSouthWest().lat();

        if (x < minX) x = minX;
        if (x > maxX) x = maxX;
        if (y < minY) y = minY;
        if (y > maxY) y = maxY;

        scope.setCenter(new _gm.LatLng(y, x));
      };

      this.limitBoundListener = _gm.event.addListener(this, 'drag', checkLimit);
      this.limitBoundListener = _gm.event.addListener(this, 'dragend', checkLimit);

    };

    /**
     * fit zoom and position to show all objects
     * @param {int} zoomMax zoom level max
     * @returns {undefined}
     */
    AGmap.prototype.fitZoom = function (zoomMax) {
      zoomMax = zoomMax || 14;

      var bounds = new _gm.LatLngBounds(),
        layer;

      for (layer in this.layers) {
        if (this.layers[layer].visible) {
          var haveObject = false;

          if (this.layers[layer].each) {
            this.layers[layer].each(function () {
              haveObject = true;
            });
          } else {
            haveObject = true; // pour les cluster
          }

          if (haveObject) {
            bounds.union(this.layers[layer].bounds);
          }
        }
      }

      this.fitBounds(bounds);
      if (this.zoom > zoomMax) this.setZoom(zoomMax);
    };

    /**
     * resize map
     */
    AGmap.prototype.resize = function () {
      _gm.event.trigger(this, 'resize');
    };

    /**
     * Add itinerary calculation support
     * @param {jQuery object} $divRecherche , the div where we'll display the directions instructions
     * @param {jQuery object} $formRecherche , the search form
     * @param {jQuery object} $fromRecherche , from field
     * @param {jQuery object} $toRecherche , to field
     * @param {jQuery object} $btnEffacer , eraser
     * @param {jQuery object} $btnGeoloc , geolocaliser btn to use my location
     * @param {jQuery object} $btnSubmit , submit btn
     * @param {callable} cb , function to call when the itinerary is done
     * @returns {undefined}
     */
    AGmap.prototype.activeItineraire = function ($divRecherche, $formRecherche, $fromRecherche, $toRecherche, $btnEffacer, $btnGeoloc, $btnSubmit, cb) {
      var scope = this;

      if ($btnGeoloc.length) {
        //Pas de support geoloc
        !navigator.geolocation && $btnGeoloc.remove();

        //Au click, geoloc
        $btnGeoloc.on('click', function (e) {
          e.preventDefault();
          if (navigator.geolocation) { //si on est en HTML5, avec navigateur qui g�olocalise
            navigator.geolocation.getCurrentPosition(geolocSuccess, geolocError);
          }

          function geolocSuccess(position) {
            if (position) {
              var latitude = position.coords.latitude,
                longitude = position.coords.longitude;
              if (latitude && longitude) {
                $fromRecherche.val([latitude, longitude].join(','));
              }
            }
          }

          function geolocError() { //Marche pas... :(
            alert('Erreur');
          }
        });
      }

      $formRecherche.on('submit', _submit);
      $btnSubmit.on('click', _submit);

      function _submit(e) {
        e.preventDefault();

        $formRecherche.addClass('loading');

        scope.triggerCalculItineraire($divRecherche, $fromRecherche.val(), $toRecherche.val(), function () {
          $formRecherche.removeClass('loading');
          cb && cb.apply(scope, arguments);
        });

      }

    };

    AGmap.prototype.triggerCalculItineraire = function ($divRecherche, from, to, cb) {
      var t = this,
        directionsDisplay = new _gm.DirectionsRenderer(),
        directionsService = new _gm.DirectionsService();

      $divRecherche.html('');

      directionsDisplay.setPanel($divRecherche[0]);
      directionsDisplay.setMap(t);

      var requeteItineraire = {
        origin: from,
        destination: to,
        region: 'fr',
        travelMode: _gm.DirectionsTravelMode.DRIVING
      };
      var itiObjects = {
        directionsDisplay: directionsDisplay,
        directionsService: directionsService
      };

      directionsService.route(requeteItineraire, function (response, status) {
        if (status === _gm.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          cb && cb(true, response, itiObjects);
        } else {
          $divRecherche.html('Error : ' + response.status);
          cb && cb(false, response, itiObjects);
        }
      });

    };

    AGmap.prototype.showItineraire = function (directionsDisplay) {
      var t = this;
      if (directionsDisplay) {
        directionsDisplay.setMap(t);
      }
    };

    AGmap.prototype.hideItineraire = function (directionsDisplay) {
      var t = this;
      if (directionsDisplay) {
        directionsDisplay.setMap(null);
      }
    };

    AGmap.prototype.createPopin = function () {
      var p = new namespace.Popin({map: this});
      p.id = this.popins.length;
      this.popins.push(p);
      return p;
    };

    AGmap.prototype.offsetCenter = function (latlng, offsetx, offsety) {
      var scope = this;
      var scale = Math.pow(2, this.getZoom());

      var worldCoordinateCenter = scope.getProjection().fromLatLngToPoint(latlng);
      var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

      var worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
      );

      var newCenter = scope.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

      scope.panTo(newCenter);
    }

    AGmap.prototype.addKmlToMap = function (kmlId, kmlUrl, onClickCallback) {
      let scope = this;
      var kmlLayer = new google.maps.KmlLayer({
        url: kmlUrl,
        preserveViewport: true,
        map: scope
      });

      if (onClickCallback) {
        kmlLayer.addListener('click', function (kmlEvent) {
          onClickCallback(kmlEvent);
        });
      }

      return kmlLayer;
    }
    AGmap.prototype.setKmlVisible = function (kmlLayer, toShow) {
      if (toShow) {
        kmlLayer.setMap(this);
      } else {
        kmlLayer.setMap(null);
      }
    };

    /**
     * ouvre une fenetre custom,
     *
     * voir =>
     * http://code.google.com/intl/fr/apis/maps/documentation/javascript/overlays.html#AddingOverlays
     * http://blog.mridey.com/2009/09/label-overlay-example-for-google-maps.html
     * http://lookmywebpage.com/api/google/google-map-custom-overlay-using-javascript-api-v3/
     */
    AGmap.prototype.openPopin = function (popin, position, marker, content, cb) {
      if (!popin) {
        if (!this.popins.length) {
          popin = this.createPopin();
        } else {
          popin = this.popins[this.popins.length - 1];
        }
      }
      position = $.isArray(position) ? new _gm.LatLng(position[0], position[1]) : position;
      popin.setContent(position, content);

      var popinClasses = 'map-infobox-content';
      if (marker && marker.data) {
        if (marker.data.col) {
          popinClasses += ' map-infobox-content-col-' + marker.data.col;
        }
        if (marker.data.id) {
          popinClasses += ' map-infobox-content-marker-' + marker.data.id;
        }
        if (marker.data.cssClasses) {
          var cssClasses = marker.data.cssClasses.split(' ');
          for (var i in cssClasses) {
            popinClasses += ' map-infobox-content-' + cssClasses[i];
          }
        }
      }
      popin.setClasses(popinClasses);

      popin.panned_ = false;
      popin.draw();
      popin.show();

      cb && cb(popin, content);

      return popin;
    };

    AGmap.prototype.closePopin = function (popin) {
      if (popin) {
        popin.close();
      } else {
        var i = this.popins.length;
        while (i--) this.popins[i].close();
      }
    };

    /**
     * show traffic info on map
     */
    AGmap.prototype.showTraffic = function () {
      if (this.trafficLayer === null) this.trafficLayer = new _gm.TrafficLayer();
      this.trafficLayer.setMap(this);
    };

    /**
     * hide traffic info on map
     */
    AGmap.prototype.hideTraffic = function () {
      if (this.trafficLayer !== null) this.trafficLayer.setMap(null);
    };

    /**
     * toggle traffic info on map
     */
    AGmap.prototype.toggleTraffic = function () {
      if (this.trafficLayer === null) this.showTraffic();
      else this.trafficLayer.getMap() ? this.hideTraffic : this.showTraffic();
    };

    /**
     * Add an image as a ground overlay within a bounding box
     * @param {} opts: {
     *      src = image source,
     *      bound:{ south:'45.322593',west:'6.528648',north:'45.329346',east:'6.539949' } boundaries
     *      limit: 0|1, limit the map to the img or not
     * @example:
     * <code>
     * map.setCustomBackground({
     *     src: ME.themeUrl+'/images/customTiles/menuireshiver.jpg',
     *     bound:{ south:'45.322593',west:'6.528648',north:'45.329346',east:'6.539949' },
     *     limit: 1
     * });
     * @returns {_L51.Gmap.prototype}
     */
    AGmap.prototype.setCustomBackground = function (opts) {
      function DisabledTilesMapType() {
      }

      var scope = this;
      var imageBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(opts.bound.south, opts.bound.west),
        new google.maps.LatLng(opts.bound.north, opts.bound.east)
      );
      var customBackgroundOverlay = new google.maps.GroundOverlay(opts.src, imageBounds);
      customBackgroundOverlay.setMap(this);
      this.fitBounds(imageBounds);

      //Limitting the map to the image will bound the map to the image edges and disable the tiles
      if (opts.limit) {

        DisabledTilesMapType.prototype.tileSize = new google.maps.Size(256, 256);
        DisabledTilesMapType.prototype.maxZoom = 20;
        DisabledTilesMapType.prototype.getTile = function (coord, zoom, ownerDocument) {
          var div = ownerDocument.createElement('div');
          div.style.width = this.tileSize.width + 'px';
          div.style.height = this.tileSize.height + 'px';
          div.style.fontSize = '0';
          return div;
        };
        DisabledTilesMapType.prototype.name = "Tile #s";
        DisabledTilesMapType.prototype.alt = "Disabled Tiles Map Type";
        var disabledTilesMapType = new DisabledTilesMapType();

        this.setOptions({
          panControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL
          },
          mapTypeControlOptions: {
            mapTypeIds: ['disabledtiles']
          }
        });
        this.mapTypes.set('disabledtiles', disabledTilesMapType);
        this.setMapTypeId('disabledtiles');
        this.limitMapBound(opts.bound.north, opts.bound.east, opts.bound.south, opts.bound.west);
        this.panToBounds(imageBounds);
        setTimeout(function () {
          var minZoomLevel = scope.getZoom();
          // Limit the zoom level
          google.maps.event.addListener(scope, 'zoom_changed', function () {
            if (scope.getZoom() < minZoomLevel) scope.setZoom(minZoomLevel);
          });
        }, 500);
      }
      return this;
    };

    /**
     * Add an IGN map type panel to the map
     * Based on the code here: http://www.jacquet80.eu/blog/post/2013/05/Cartes-IGN-dans-Google-Maps-API
     * @param {type} opts
     * opts.ignKey est votre clé API Géoportail,
     * opts.layer est la couche souhaitée : GEOGRAPHICALGRIDSYSTEMS.MAPS pour les cartes classiques, ou encore GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.CLASSIQUE et GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.STANDARD pour les nouvelles cartes « Scan Express » (respectivement en colorisation classique et pastel), ORTHOIMAGERY.ORTHOPHOTOS pour des images satellite
     * opts.autoSet , (optional), false by default. Do you want to set this layer as active too?
     * opts.title pour choisir le libellé du bouton
     * @returns {AGmap_L3.AGmap.prototype}
     */
    AGmap.prototype.enableIGNSupport = function (opts) {
      //Options
      if (!opts.ignKey) {
        throw "The enableIGNSupport method requires a valid IGN key passed in opts.ignKey";
      }
      if (!opts.layer) {
        opts.layer = 'GEOGRAPHICALGRIDSYSTEMS.MAPS';
      }
      if (!opts.autoSet) {
        opts.autoSet = false;
      }
      if (!opts.title) {
        opts.title = 'IGN';
      }

      //Define IGN map type
      var ignMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return "http://gpp3-wxs.ign.fr/" + opts.ignKey + "/geoportail/wmts?LAYER=" +
            opts.layer +
            "&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0" +
            "&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX=" +
            zoom + "&TILEROW=" + coord.y + "&TILECOL=" + coord.x;
        },
        tileSize: new google.maps.Size(256, 256),
        name: opts.title,
        maxZoom: 18
      });
      this.mapTypes.set(opts.title, ignMapType);

      //Add IGN map type support
      this.__mapTypeIds.push(opts.title);
      this.setOptions({
        mapTypeControlOptions: {
          mapTypeIds: this.__mapTypeIds
        }
      });

      //Set this layer as active
      if (opts.autoSet) {
        this.setMapTypeId(opts.title);
      }

      return this;
    };

    /**
     * Add an OSM map type panel to the map
     * @param {type} opts
     * opts.autoSet , (optional), false by default. Do you want to set this layer as active too?
     * opts.title pour choisir le libellé du bouton
     * opts.tilesURL pour choisir l'url des tiles a recuperer, exemple pour une map cyclo : http://b.tile.thunderforest.com/cycle
     * @returns {AGmap}
     */
    AGmap.prototype.enableOSMSupport = function (opts) {
      if (!opts.autoSet) {
        opts.autoSet = false;
      }
      if (!opts.title) {
        opts.title = 'OSM';
      }
      if (!opts.tilesURL) {
        opts.tilesURL = 'http://tile.openstreetmap.org';
      }

      //Define OSM map type
      var osmMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return opts.tilesURL + "/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
        },
        tileSize: new google.maps.Size(256, 256),
        name: opts.title,
        maxZoom: 18
      });
      this.mapTypes.set(opts.title, osmMapType);

      //Add OSM map type support
      this.__mapTypeIds.push(opts.title);
      this.setOptions({
        mapTypeControlOptions: {
          mapTypeIds: this.__mapTypeIds
        }
      });

      //Set this layer as active
      if (opts.autoSet) {
        this.setMapTypeId(opts.title);
      }

      return this;
    },

      /**
       * display button to activate / desactivate scroll
       */
      AGmap.prototype.initScrollBtn = function () {
        var scope = this;
        scope.scrollBtn = document.createElement('a');
        scope.scrollBtn.className = 'gmap-btn scroll-toggle';
        scope.scrollActive = true;
        scope.el.appendChild(scope.scrollBtn);
        scope.scrollBtn.addEventListener('click', function (e) {
          e.preventDefault();
          scope.setScrollable(!scope.scrollActive);
        });
      },

      AGmap.prototype.setScrollable = function (scrollable) {
        var scope = this;
        scope.scrollActive = scrollable;
        //scrollable ? scope.scrollBtn.classList.remove('locked') : scope.scrollBtn.classList.add('locked');
        scope.setOptions({scrollwheel: scrollable});
        /*if (namespace.isMobile && namespace.isMobile()) {
            scope.setOptions({draggable: scrollable});
        }*/
      },

      /**
       *
       * @returns {undefined}init custom zoom buttons
       */
      AGmap.prototype.initZoomButtons = function () {
        var scope = this;
        scope.setOptions({disableDefaultUI: true});
        // init elements
        scope.zoomMoreBtn = document.createElement('a');
        scope.zoomLessBtn = document.createElement('a');
        scope.zoomMoreBtn.className = 'gmap-btn zoom-more';
        scope.zoomLessBtn.className = 'gmap-btn zoom-less';
        scope.zoomBtnContainer = document.createElement('div');
        scope.zoomBtnContainer.className = 'zoom-buttons';
        scope.zoomBtnContainer.appendChild(scope.zoomMoreBtn);
        scope.zoomBtnContainer.appendChild(scope.zoomLessBtn);
        scope.el.appendChild(scope.zoomBtnContainer);

        // init events
        scope.zoomMoreBtn.addEventListener('click', function (e) {
          e.preventDefault();
          scope.setZoom(scope.getZoom() + 1);
        });
        scope.zoomLessBtn.addEventListener('click', function (e) {
          e.preventDefault();
          scope.setZoom(scope.getZoom() - 1);
        });
      };

    AGmap.prototype.getGeolocPositionMarker = function (newCenter, opts) {
      var markerOpts = {map: this};
      if (opts.markerOpts) {
        markerOpts = $.extend(markerOpts, opts.markerOpts);
      }
      return new google.maps.Marker(markerOpts);
    };

    AGmap.prototype.getDefaultMarkerUl = function () {
      return 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png';
    };

    AGmap.prototype.getPopinWrapper = function (popin) {
      return popin.divWrap;
    };

    /**
     * instanciation plugin like, more OOP
     * var map = $('#map').gmap(options*, style, callBack);
     * option = {
     *      zoom: int,
     *      center: array lat lng or object LanLng,
     *      mapTypeId: _gm.MapTypeId.ROADMAP (const _gm.MapTypeId)
     *   }
     *  callBack : function lancée quand toutes les tuiles sont chargées
     *
     *  return un object noeGooglemap
     */
    $.fn.gmap = function (options, callBack) {
      "use strict";
      if (this.length) {
        var gg = new AGmap($(this)[0], options, callBack);
        $(this).data('gmap', gg);
        return gg;
      }

      return false;
    };

  })(jQuery, window.google && google.maps, window.wonderwp || window);
});
