var namespace = window.wonderwp;

namespace.needOSM(function () {
  (function ($, L, namespace) {
    if (!L) return;
    "use strict";

    var OSMLayer = namespace.OSMLayer || namespace.AOSMLayer,
      OSMCluster = namespace.OSMCluster || namespace.AOSMCluster;

    // ------------------------------------------------------------------------
    // AOSM
    // ------------------------------------------------------------------------

    /**
     * create a new map
     * @param {DOMElement} el
     * @param {object} opt
     * @param {fn} callback
     * @returns AOpenStreetMap
     */
    function AOpenStreetMap(el, opt, callback) {
      var t = this;
      if (!opt.zoom) throw "Zoom required";
      if (!opt.center) throw "Center required";

      opt.center = opt.center.split(',');
      if (!opt.scrollWheelZoom) {
        opt.scrollWheelZoom = false;
      }

      t.map = new L.Map(el, opt);

      var defaultTileLayerOpts = {
          url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        },
        passedTileLayerOpts = opt.tileLayerOpts || {},
        tileLayerOpts = $.extend(defaultTileLayerOpts, passedTileLayerOpts);

      var tileLayer = L.tileLayer(tileLayerOpts.url, {
        attribution: tileLayerOpts.attribution
      }).addTo(t.map);

      L.Icon.Default.imagePath = 'https://unpkg.com/leaflet@1.6.0/dist/images/';

      t.layers = [];
      t.popins = [];
      t.clusterGroups = [];

      var loaded = false;

      function _mapLoaded() {
        if (callback && !loaded) {
          callback();
        }
        tileLayer.off('load', _mapLoaded);
        loaded = true;
      }

      tileLayer.on('load', _mapLoaded);

      $(el).data('osm-map', this);
    };

    namespace.AOpenStreetMap = AOpenStreetMap;

    /**
     * create a layer on the map
     * @param {string} name
     * @param {MarkerOptions} defOptMarker
     * @returns {OSMLayer}
     */
    AOpenStreetMap.prototype.addLayer = function (name, defOptMarker, defOptPolyline, defOptPolygon, defOptKml) {

      var layer = new OSMLayer(this, name, defOptMarker, defOptPolyline, defOptPolygon, defOptKml);
      this.layers[layer.name] = layer;
      return layer;

    };

    /**
     * create a clustered layer on the map
     * @param {string} name
     * @param {MarkerOptions} defOptMarker
     * @param {object} clusterOptions
     * @param {string} groupName to group multiple layers in one same cluster group
     * @returns {OSMCluster}
     */
    AOpenStreetMap.prototype.addClusterLayer = function (name, defOptMarker, groupName, clusterOptions) {

      var layer = new OSMCluster(this, name, defOptMarker, groupName, clusterOptions);
      this.layers[layer.name] = layer;
      return layer;
    };

    /**
     * get a layer by its name
     * @param {string} name
     * @returns {OSMLayer|OSMCluster}
     */
    AOpenStreetMap.prototype.getLayer = function (name) {
      return this.layers[name] === undefined ? false : this.layers[name];
    };

    /**
     * remove layer by its name
     * @param {string} name
     */
    AOpenStreetMap.prototype.removeLayer = function (name) {
      var layer = this.getLayer(name);
      if (layer === false) return;

      layer.clear();
      delete this.layers[name];
    };

    AOpenStreetMap.prototype.limitMapBound = function (n, e, s, w) {
      var southWest = L.latLng(s, w),
        northEast = L.latLng(n, e),
        bounds = L.latLngBounds(southWest, northEast);
      this.map.setMaxBounds(bounds);
    };

    /**
     * fit zoom and position to show all objects
     * @param {int} zoomMax zoom level max
     * @returns {undefined}
     */
    AOpenStreetMap.prototype.fitZoom = function (zoomMax) {
      zoomMax = zoomMax || 14;

      var bounds = new L.LatLngBounds(),
        layer;

      for (layer in this.layers) {
        if (this.layers[layer].visible) {
          bounds.extend(this.layers[layer].getBounds());
        }
      }

      this.map.fitBounds(bounds);
      if (this.map.getZoom() > zoomMax) this.map.setZoom(zoomMax);
    };

    /**
     * resize map
     */
    AOpenStreetMap.prototype.resize = function () {
      $('window').trigger('resize');
    };

    AOpenStreetMap.prototype.offsetCenter = function (LatLng, offsetx, offsety) {
      offsety = offsety * -1;
      var scale = this.map.getZoom();
      var centerPoint = this.map.project(LatLng, scale);

      var pixelOffset = new L.point([offsetx, offsety]);

      var pTmp = new L.point([centerPoint.x - pixelOffset.x, centerPoint.y - pixelOffset.y]);
      var newCenter = this.map.unproject(pTmp, scale);

      this.panTo(newCenter);

    };

    AOpenStreetMap.prototype.addKmlToMap = function (kmlId, kmlUrl, onClickCallback) {
      var kmlLayer = new L.KML(kmlUrl);

      if (onClickCallback) {
        kmlLayer.on('click', function (kmlEvent) {
          onClickCallback(kmlEvent);
        });
      }

      this.map.addLayer(kmlLayer);

      return kmlLayer;
    };

    AOpenStreetMap.prototype.setKmlVisible = function (kmlLayer, toShow) {
      if (toShow) {
        kmlLayer.addTo(this.map);
      } else {
        kmlLayer.removeFrom(this.map);
      }
    };

    AOpenStreetMap.prototype.panTo = function (LatLng) {
      this.map.panTo(LatLng);
    };
    AOpenStreetMap.prototype.setCenter = function (LatLng) {
      this.map.setView(LatLng);
    };
    AOpenStreetMap.prototype.setZoom = function (zoom) {
      this.map.setZoom(zoom);
    };
    AOpenStreetMap.prototype.getZoom = function () {
      return this.map.getZoom();
    };

    AOpenStreetMap.prototype.activeItineraire = function ($divRecherche, $formRecherche, $fromRecherche, $toRecherche, $btnEffacer, $btnGeoloc, $btnSubmit, cb) {
      var scope = this;

      if ($btnGeoloc.length) {
        //Pas de support geoloc
        !navigator.geolocation && $btnGeoloc.remove();

        //Au click, geoloc
        $btnGeoloc.on('click', function (e) {
          e.preventDefault();
          if (navigator.geolocation) { //si on est en HTML5, avec navigateur qui g�olocalise
            navigator.geolocation.getCurrentPosition(geolocSuccess, geolocError);
          }

          function geolocSuccess(position) {
            if (position) {
              var latitude = position.coords.latitude,
                longitude = position.coords.longitude;
              if (latitude && longitude) {
                $fromRecherche.val([latitude, longitude].join(','));
              }
            }
          }

          function geolocError() { //Marche pas... :(
            alert('Erreur');
          }
        });
      }

      $formRecherche.on('submit', _submit);
      $btnSubmit.on('click', _submit);

      function _submit(e) {
        e.preventDefault();

        $formRecherche.addClass('loading');

        scope.triggerCalculItineraire($divRecherche, $fromRecherche.val(), $toRecherche.val(), function () {
          $formRecherche.removeClass('loading');
          cb && cb.apply(scope, arguments);
        });

      }

    };

    AOpenStreetMap.prototype.triggerCalculItineraire = function ($divRecherche, from, to, cb) {
      var t = this,
        directionsDisplay = new _gm.DirectionsRenderer(),
        directionsService = new _gm.DirectionsService();

      $divRecherche.html('');

      directionsDisplay.setPanel($divRecherche[0]);
      directionsDisplay.setMap(t);

      var requeteItineraire = {
        origin: from,
        destination: to,
        region: 'fr',
        travelMode: _gm.DirectionsTravelMode.DRIVING
      };
      var itiObjects = {
        directionsDisplay: directionsDisplay,
        directionsService: directionsService
      };

      directionsService.route(requeteItineraire, function (response, status) {
        if (status === _gm.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          cb && cb(true, response, itiObjects);
        } else {
          $divRecherche.html('Error : ' + response.status);
          cb && cb(false, response, itiObjects);
        }
      });

    };

    AOpenStreetMap.prototype.showItineraire = function (directionsDisplay) {
      var t = this;
      if (directionsDisplay) {
        directionsDisplay.setMap(t);
      }
    };

    AOpenStreetMap.prototype.hideItineraire = function (directionsDisplay) {
      var t = this;
      if (directionsDisplay) {
        directionsDisplay.setMap(null);
      }
    };

    AOpenStreetMap.prototype.createPopin = function () {
      var p = L.popup({
        offset: [0, -20]
      });
      this.popins.push(p);
      return p;
    };

    /**
     * ouvre une fenetre custom,
     *
     * voir =>
     * http://code.google.com/intl/fr/apis/maps/documentation/javascript/overlays.html#AddingOverlays
     * http://blog.mridey.com/2009/09/label-overlay-example-for-google-maps.html
     * http://lookmywebpage.com/api/google/google-map-custom-overlay-using-javascript-api-v3/
     */
    AOpenStreetMap.prototype.openPopin = function (popin, position, marker, content, cb) {
      if (!popin) {
        if (!this.popins.length) {
          popin = this.createPopin();
        } else {
          popin = this.popins[this.popins.length - 1];
        }
      }

      var popinContent = this.createPopinContent(marker, content);

      popin.setLatLng(position)
        .setContent(popinContent)
        .openOn(this.map);

      cb && cb(popin, content);

      return popin;
    };

    AOpenStreetMap.prototype.createPopinContent = function (marker, content) {
      var popinClasses = 'map-infobox-content';
      if (marker && marker.data) {
        if (marker.data.col) {
          popinClasses += ' map-infobox-content-col-' + marker.data.col;
        }
        if (marker.data.id) {
          popinClasses += ' map-infobox-content-marker-' + marker.data.id;
        }
        if (marker.data.cssClasses) {
          var cssClasses = marker.data.cssClasses.split(' ');
          for (var i in cssClasses) {
            popinClasses += ' map-infobox-content-' + cssClasses[i];
          }
        }
      }

      var popinContent = '<div class="' + popinClasses + '">';

      if (typeof content === 'string') {
        popinContent += '<div class="item-info"><div class="item-desc">' + content + '</div></div>';
      } else {
        if (content.img) {
          popinContent += '<div class="item-image">' + content.img + '</div>';
        }
        popinContent += '<div class="item-info">';
        if (content.title) {
          popinContent += '<div class="item-titre">' + content.title + '</div>';
        }
        if (content.baseline) {
          popinContent += '<div class="map-layer-baseline">' + content.baseline + '</div>';
        }
        if (content.desc) {
          popinContent += '<div class="item-desc">' + content.desc + '</div>';
        }
        popinContent += '</div>';
      }

      popinContent += '</div>';

      return popinContent;
    }

    AOpenStreetMap.prototype.closePopin = function (popin) {
      if (popin) {
        popin.close();
      } else {
        var i = this.popins.length;
        while (i--) this.popins[i].close();
      }
    };

    /**
     * show traffic info on map
     */
    AOpenStreetMap.prototype.showTraffic = function () {
      if (this.trafficLayer === null) this.trafficLayer = new _gm.TrafficLayer();
      this.trafficLayer.setMap(this);
    };

    /**
     * hide traffic info on map
     */
    AOpenStreetMap.prototype.hideTraffic = function () {
      if (this.trafficLayer !== null) this.trafficLayer.setMap(null);
    };

    /**
     * toggle traffic info on map
     */
    AOpenStreetMap.prototype.toggleTraffic = function () {
      if (this.trafficLayer === null) this.showTraffic();
      else this.trafficLayer.getMap() ? this.hideTraffic : this.showTraffic();
    };

    /**
     * Add an image as a ground overlay within a bounding box
     * @param {} opts: {
     *      src = image source,
     *      bound:{ south:'45.322593',west:'6.528648',north:'45.329346',east:'6.539949' } boundaries
     *      limit: 0|1, limit the map to the img or not
     * @example:
     * <code>
     * map.setCustomBackground({
     *     src: ME.themeUrl+'/images/customTiles/menuireshiver.jpg',
     *     bound:{ south:'45.322593',west:'6.528648',north:'45.329346',east:'6.539949' },
     *     limit: 1
     * });
     * @returns {_L51.OSM.prototype}
     */
    AOpenStreetMap.prototype.setCustomBackground = function (opts) {
      return this;
    };

    AOpenStreetMap.prototype.getGeolocPositionMarker = function (newCenter, opts) {
      var markerOpts = {map: this.map};
      if (opts.markerOpts) {
        markerOpts = $.extend(markerOpts, opts.markerOpts);
      }
      var geolocMarker = L.marker([newCenter.lat, newCenter.lng], markerOpts).addTo(this.map);

      geolocMarker.setPosition = function (LatLng) {
        this.setLatLng(LatLng);
      }

      return geolocMarker;
    };

    AOpenStreetMap.prototype.getDefaultMarkerUl = function () {
      return 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png';
    };

    AOpenStreetMap.prototype.getPopinWrapper = function (popin) {
      return popin._wrapper;
    };

    AOpenStreetMap.prototype.createInfoWindow = function (opt, marker) {
      var offset = [];
      if (opt.offset) {
        offset = opt.offset;
      } else {
        if (marker && marker.options && marker.options.icon && marker.options.icon.options && marker.options.icon.options.iconSize) {
          var iconHeight = marker.options.icon.options.iconSize[1];
          offset = [0, -iconHeight - 10]
        } else {
          offset = [0, -20];
        }
      }

      let className = 'marker-popup';
      if (marker.className && marker.className.length > 0) {
        className += ' marker-popup-' + marker.className;
      }

      return L.popup({
        offset: L.point(parseFloat(offset[0]), parseFloat(offset[1])),
        className: className
      })
        .setLatLng(marker.getPosition())
        .setContent(opt.content);
    }

    AOpenStreetMap.prototype.openInfoWindow = function (marker) {
      marker.hoverPopin.openOn(this.map);
    };
    AOpenStreetMap.prototype.closeInfoWindow = function (marker) {
      marker.hoverPopin.removeFrom(this.map);
    }

    AOpenStreetMap.prototype.enableMarkerHover = function (marker, cbIn, cbOut) {
      marker.on('mouseover', function () {
        cbIn(marker);
      });
      marker.on('mouseout', function () {
        cbOut(marker);
      });
    }

  })(jQuery, window.L, window.wonderwp);
});
