(window.wonderwp || window).needOSM(function () {
  (function ($, namespace) {

    function CustomMarker(opt) {
      var className = opt.className || '';
      var content = opt.content || '';

      if (opt.data) {
        this.data = opt.data;
        if (this.data.col) {
          className += ' custom-marker-col' + this.data.col;
        }
      }

      if (opt.i) {
        className += ' marker-index-' + opt.i;
      }

      var icon = L.divIcon({
        className: 'custom-marker ' + className,
        html: content,
        iconSize: [10, 10],
        iconAnchor: [5, 20]
      });

      this.marker = L.marker([opt.lat, opt.lng], {icon: icon});
    }

    CustomMarker.prototype.getMarker = function () {
      return this.marker;
    }

    namespace.OsmCustomMarker = CustomMarker;

  })(jQuery, window.wonderwp || window);
});
