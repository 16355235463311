window.wonderwp.needOSM(function () {
  (function ($, L, namespace) {
    if (!L) return;
    "use strict";

    /* -------------------------------------------------------------------------
     * ANoeOSMCluster
     ------------------------------------------------------------------------- */

    /**
     * create a new map clustering layer
     * @param {NoeOSM} map
     * @param {string} name
     * @param {MarkerOptions} defOptMarker
     * @param {string} groupName to group multiple layers in one same cluster group
     * @param {object} clusterOptions
     * @returns {ANoeOSMCluster}
     */
    function OSMCluster(map, name, defOptMarker, groupName, clusterOptions) {

      this.isCluster = true;
      this.map = map;
      this.name = name || 'all';
      this.groupName = groupName || 'all';

      // build options
      this.defOptMarker = defOptMarker || {};

      clusterOptions = clusterOptions || {};
      var clusterOptionsDef = {
        showCoverageOnHover: false,
        chunkedLoading: true,
      };

      if (this.defOptMarker.icon) {
        var iconOpt = this.defOptMarker.icon;
        clusterOptionsDef.iconCreateFunction = function (cluster) {
          return new L.DivIcon({
            html: '<div style="background-image: url(' + iconOpt.url + ');"><span>' + cluster.getChildCount() + '</span></div>',
            className: 'gmap-cluster',
            iconSize: iconOpt.scaledSize
          });
        };
      }

      clusterOptions = $.extend(clusterOptionsDef, clusterOptions);

      // init vars
      this.visible = true;
      this.currentId = 0;
      this.listeners = [];
      this.bounds = new L.LatLngBounds();
      this.markers = [];

      this.map.layers[this.name] = this;

      // create cluster group
      if (this.map.clusterGroups[this.groupName]) {
        this.markerClusterer = this.map.clusterGroups[this.groupName];
      } else {
        this.markerClusterer = new L.MarkerClusterGroup(clusterOptions);
        this.map.clusterGroups[this.groupName] = this.markerClusterer;
        this.map.map.addLayer(this.markerClusterer);
      }

      // used to dipatch events to NoeCarto
      this.eventDispatcher = L.marker([0, 0]);

    }

    /**
     * create a marker
     * @param {float} lat
     * @param {float} lng
     * @param {MarkerOptions} opt
     * @param {boolean} nodraw set to true if you don't want to calculate cluster (if you add lots of marker at the same time)
     * @returns {Marker}
     */
    OSMCluster.prototype.addMarker = function (lat, lng, opt, nodraw) {

      opt = $.extend({}, this.defOptMarker, opt);

      if (opt.icon) {
        opt.icon = namespace.wwpIconOptToOSM(opt.icon);
      }

      if (opt.id === undefined) {
        this.currentId++;
        opt.id = this.currentId;
      }

      var marker;
      if (opt.custom) {
        marker = new namespace.OSMCustomMarker(opt.position, opt.map, opt.custom.className, opt.custom.content);
      } else {
        marker = new L.Marker([lat, lng], opt);
      }
      marker.noeLayer = this;
      for (var i in opt) {
        marker[i] = opt[i];
      }
      marker.getPosition = function(){
        return this._latlng;
      };

      this.markers.push(marker);

      var t = this;
      this.addObjectListener(marker, 'click', function (e) {
        t.eventDispatcher.fireEvent(namespace.OSMLayer.Events.CLICK_OBJECT, {
          _type: namespace.OSMLayer.ObjectTypes.MARKER,
          _object: marker
        });
      });

      if(!nodraw) {
        this.markerClusterer.addLayer(marker);
      }

      return marker;

    };

    OSMCluster.prototype.clearMarkers = function(){
      this.markerClusterer.clearLayers();
    }

    /**
     * cleanly remove a marker from this layer, removing from cluster and markers array
     * @param {type} marker
     * @returns {undefined}
     */
    OSMCluster.prototype.removeMarker = function (marker) {
      // remove marker from array
      var index = this.markers.indexOf(marker);
      if (index > -1) {
        this.markers.splice(index, 1);
      }

      // remove from cluster
      this.markerClusterer.removeLayer(marker);
    };

    /**
     *
     * @param {object} obj
     * @param {string} event
     * @param {function} callback
     */
    OSMCluster.prototype.addObjectListener = function (obj, event, callback, context) {
      obj.on(event, callback, context);
    };

    /**
     * remove all objects
     */
    OSMCluster.prototype.clear = function () {

      this._removeMarkers();

      this.listeners = [];

    };

    /**
     * show / hide layer
     * @param {boolean} visible
     */
    OSMCluster.prototype.setVisible = function (visible) {
      if (visible === this.visible) return;
      var t = this;

      if (visible) {
        this.markerClusterer.addLayers(this.markers);
      } else {
        this._removeMarkers();
      }

      t.visible = visible;

    };

    /**
     * toggle layer
     */
    OSMCluster.prototype.toggle = function () {

      this.setVisible(!this.visible);

    };

    /*
     * remove markers of this layer from cluster group
     */
    OSMCluster.prototype._removeMarkers = function () {
      this.markerClusterer.removeLayers(this.markers);
    };

    OSMCluster.prototype.getBounds = function () {
      return this.markerClusterer.getBounds();
    };

    namespace.OSMCluster = OSMCluster;

  })(jQuery, window.L, window.wonderwp);
});
