var namespace = window.wonderwp,
  w = window;
namespace.__needGM = [];
namespace.__wantGM = [];
namespace.__gmLoading = false;
namespace.__gmLoadedHasBeenCalled = false;

/**
 * wrap any code that need google map on the callback of this function
 * @param {function} cb
 */
namespace.needGooglemap = function (cb) {
  if (w.google && w.google.maps) {
    cb && cb();
  } else {
    cb && namespace.__needGM.push(cb);
  }
};
/**
 * use this function when a part of your code want google map, callback will execute when gmap is loaded asynchronously
 * @param {function} cb
 * @param {string} url optional
 */
namespace.wantGooglemap = function (cb, url) {

  if (w.google && w.google.maps) {
    if (!namespace.__gmLoadedHasBeenCalled) {
      namespace.__gmLoaded();
    }
    cb && cb();
  } else {
    cb && namespace.__wantGM.push(cb);
    if (!namespace.__gmLoading) {
      namespace.__gmLoading = true;
      //load google maps script
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url + "&callback=window.wonderwp.__gmLoaded";
      document.body.appendChild(script);
    }
  }
};

namespace.__gmLoaded = function () {
  if (w.google && w.google.maps) {
    for (var i = 0; i < namespace.__needGM.length; i++) {
      namespace.__needGM[i]();
    }

    for (i = 0; i < namespace.__wantGM.length; i++) {
      namespace.__wantGM[i]();
    }
    namespace.__gmLoading = false;
  } else {
    namespace.__gmLoading = false;
    namespace.wantGooglemap();
  }
  namespace.__gmLoadedHasBeenCalled = true;
};

namespace.gmapExtend = function (childCtor, parentCtor) {
  /** @constructor */
  function tempCtor() {}

  tempCtor.prototype = parentCtor.prototype;
  childCtor.superClass_ = parentCtor.prototype;
  childCtor.prototype = new tempCtor();
  childCtor.prototype.constructor = childCtor;
};
