(window.wonderwp || window).needGooglemap(function () {
  (function ($, namespace) {
    function Popin(opt_options) {
      // Initialization
      this.setValues(opt_options);
      this.panned_ = false;

      // popin specific
      this.div_ = document.createElement('div');
      this.div_.style.cssText = 'position: absolute; display:none; height:auto; background:white';
      this.div_.className = 'map-infobox';

      this.divWrap = document.createElement('div');
      this.divWrap.className = 'map-infobox-content';

      this.divInfoWrap = document.createElement('div');
      this.divInfoWrap.className = 'item-info';

      this.divTitle = document.createElement('div');
      this.divTitle.className = 'item-titre';

      this.divImg = document.createElement('div');
      this.divImg.className = 'item-image';

      this.divDesc = document.createElement('div');
      this.divDesc.className = 'item-desc';


      this.aLien = document.createElement('a');
      this.aLien.innerHTML = '';
      this.aLien.target = '_blank';
      this.aLien.className = 'map-layer-link';

      this.parts = [
        ['title', 'divTitle'],
        ['img', 'divImg'],
        ['desc', 'divDesc'],
        ['link', 'aLien']
      ];

      this.aClose = document.createElement('a');
      this.aClose.href = "#";
      var scope = this;
      $(this.aClose).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        scope.hide();
      });
      this.aClose.innerHTML = '&times;';
      this.aClose.className = 'close';

      this.divWrap.appendChild(this.aClose);
      this.divWrap.appendChild(this.divImg);
      this.divInfoWrap.appendChild(this.divTitle);
      this.divInfoWrap.appendChild(this.aLien);
      this.divInfoWrap.appendChild(this.divDesc);
      this.divWrap.appendChild(this.divInfoWrap);
      this.div_.appendChild(this.divWrap);
      this.$div = $(this.div_);
      this.position = null;
    };

    Popin.prototype = new google.maps.OverlayView;

    // Implement onAdd
    Popin.prototype.onAdd = function () {
      var pane = this.getPanes().floatPane;
      pane.appendChild(this.div_);
      this.div_.style.visibility = "visible";
    };

    Popin.prototype.setContent = function (position, data) {
      this.position = position;
      if (data) {
        if (typeof data === 'string') {
          while (i--) this[this.parts[i][1]].style.display = 'none';
          this.divDesc.innerHTML = data;
          this.divDesc.style.display = 'block';
          var i = this.parts.length;
          this.divWrap.className += ' fromString';
        } else {
          var i = this.parts.length,
            e = null,
            str = null;
          while (i--) {
            e = this[this.parts[i][1]];
            str = data[this.parts[i][0]];
            if (str && str.length) {
              e.innerHTML = str;
              e.style.display = 'block';
            } else {
              e.innerHTML = '';
              e.style.display = 'none';
            }
          }
          this.divWrap.className += ' fromObject';
        }
      }

    };

    Popin.prototype.setClasses = function (classes) {
      this.divWrap.className = classes;
    };

    Popin.prototype.hide = function () {
      if (this.div_) {
        this.div_.style.visibility = "hidden";
      }
      $('body').removeClass('has-popin');
    };

    Popin.prototype.close = function (that) {
      that = that || this;
      this.hide();
    };
    Popin.prototype.show = function () {
      if (this.div_) {
        this.div_.style.visibility = "visible";
      }
      $('body').addClass('has-popin');
    };

    Popin.prototype.toggle = function () {
      if (this.div_)
        this.div_.style.visibility === 'hidden' ? this.show() : this.hide();
    };

    Popin.prototype.setVisible = function (visible) {
      this[visible ? 'show' : 'hide']();
    };

    // Implement onRemove
    Popin.prototype.onRemove = function () {
      this.div_.parentNode.removeChild(this.div_);
    };

    // Implement draw
    Popin.prototype.draw = function () {

      // Since we use bounds changed listener, projection is sometimes null
      if (this.getProjection()) {

        // Seems to be when a new Popin is drawn, but not fully on screen.
        if (this.div_ == null) {
          return;
        }
        this.width_ = this.$div.outerWidth();
        this.height_ = this.$div.outerHeight();
        this.offsetX_ = -(this.width_ / 2);
        this.offsetY_ = -(this.height_ + 50);

        // This gives us the position in the tiles div.
        var pixPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        if (!pixPosition) {
          return;
        }

        this.div_.style.left = (pixPosition.x + this.offsetX_) + 'px';
        this.div_.style.bottom = -(pixPosition.y - 50) + 'px';
        this.div_.style.display = 'block';

        var shouldPan = !this.panned_;
        if (shouldPan) {
          this.panned_ = true;
          this.maybePanMap(this.offsetX_, this.offsetY_);
        }
      }
    };

    /**
     * Pan the map to fit the Popin,
     * if its top or bottom corners aren't visible.
     */
    Popin.prototype.maybePanMap = function (offset_x, offset_y) {
      // if we go beyond map, pan map
      var map = this.map;
      var projection = this.getProjection();
      var bounds = map.getBounds();
      if (!bounds || !projection) {
        return;
      }

      var anchorPixel = projection.fromLatLngToDivPixel(this.position);
      var top_left = new google.maps.Point(
          anchorPixel.x + offset_x - 20,
          anchorPixel.y + offset_y - 20
        ),
        bottom_right = new google.maps.Point(
          top_left.x + this.width_ + 40,
          top_left.y + this.height_ + 40
        );
      var sw = projection.fromDivPixelToLatLng(top_left),
        ne = projection.fromDivPixelToLatLng(bottom_right);

      // The bounds of the infowindow
      if (!bounds.contains(ne) || !bounds.contains(sw)) {
        map.panToBounds(new google.maps.LatLngBounds(sw, ne));
      }

    };

    namespace.Popin = Popin;
  })(jQuery, window.wonderwp || window);
});
