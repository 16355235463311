(function ($, ns) {

  var GmapLocalizer = function () {
    this.geocoder = null;
  };

  GmapLocalizer.prototype = {

    /**
     * Geoloc request handler
     * @param cb
     *
     *
     * @example :
     * this.element.find('[data-geoloc-trigger]').on('click', (e) => {
     *     e.preventDefault();
     *     let $trigger = $(e.currentTarget);
     *
     *     this.EventManager.trigger('geoloc-request', {
     *       $trigger: $trigger,
     *       mapId: $trigger.data('map'),
     *       zoomOnSuccess: 8,
     *       debugPosition: {coords: {latitude: 43.622812499999995, longitude: 3.9137797, altitude: null, accuracy: 20, altitudeAccuracy: null, heading: null, speed: null,}}
     *     });
     * });
     *
     */
    handleGeolocRequest: function (cb) {
      var self = this;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          cb && cb(position, null, null);
        }, function (error) {
          var info = self.explicitLocationError(error);
          console.error(info);
          cb && cb(null, info, error);
        });
      } else {
        var error = {code: 'not.supported'};
        var info = self.explicitLocationError(error);
        console.error(info);
        cb && cb(null, info, error);
      }
    },

    explicitLocationError: function (error) {
      var info = "Erreur lors de la géolocalisation : ";
      switch (error.code) {
        case error.TIMEOUT:
          info += "Timeout !";
          break;
        case error.PERMISSION_DENIED:
          info += "La géolocalisation a été refusée";
          break;
        case error.POSITION_UNAVAILABLE:
          info += "La position n’a pu être déterminée";
          break;
        case 'not.supported':
          info += "La géolocalisation n'est pas supportée par ce navigateur";
          break;
        case error.UNKNOWN_ERROR:
        default:
          info += "Erreur inconnue";
          break;
      }

      return info;
    },

    /**
     * Geocode request handler
     * @param adresse
     * @param cb
     * @returns {boolean}
     *
     * @example
     * this.element.on('submit', (e) => {
     *     e.preventDefault();
     *     let $trigger = $(e.currentTarget);
     *     let addressToSearch = $trigger.find('#store-search').val()+', France';
     *
     *     this.EventManager.trigger('geocoding-request', {
     *       $trigger: $trigger,
     *       mapId: $trigger.data('map'),
     *       zoomOnSuccess: 8,
     *       address: addressToSearch
     *     });
     * });
     */
    geocodeAddress: function (adresse, cb) {

      if (this.geocoder === null) {
        this.geocoder = new window.google.maps.Geocoder();
      }

      if (!adresse.length) {
        cb([]);
        return false;
      }

      var ret = this.geocoder.geocode({"address": adresse}, function (data, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          cb(data, null); //data[0].geometry.location
        } else {
          if (status === 'ZERO_RESULTS') {
            cb([], 'Pas de résultat pour cette requête');
          } else {
            var info = "Address geocoding error : " + status;
            console.error(info);
            cb([], info);
          }
        }
      });
    }
  };

  ns.GmapLocalizer = GmapLocalizer;

})(jQuery, window.wonderwp);
